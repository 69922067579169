<template>
  <div class="kdz-config-form" v-if="isSidebarOpen">
    <div class="kdz-sidebar-head">
      <a-input
          id="cloudServerName"
          :class="validateServerName ? 'serverNameValidation' : ''"
          type="text"
          v-model:value="serverName"
          :maxLength="28"
          placeholder="Servername"
          style="margin-bottom: 15px;"
      ></a-input>
        <a-button
          @click="cancelConf=true"
          class="exit-button"
          type="primary"
        >
         <span class="icon-round">X</span>
        </a-button>
    </div>
    <!-- Config for Resources in Sidebar for iaas and desktop/cloudserver -->
    <div class="settings-container" v-if="selectedServer.type === 'iaas' || selectedServer.type === 'cloudServer'">

      <span v-if="validateServerName && submitButtonClicked" style="color: red; font-weight: bold;">Ein gültiger Name besteht aus Zahlen, Buchstaben und &_. und besteht aus maximal 28 Zeichen!</span>
      <ConfigFormField
        v-for="(field, j) of numberFields"
        :key="j"
        :options="field"
        :isResource="true"
        :requireCpu="requireCpu"
        :requireRam="requireRam"
        :requireStorage="requireStorage"
        :submitButtonClicked="submitButtonClicked"
        :serverType="selectedServer.type"

      />
      <ConfigOsList
      :requireOS="requireOS"
      :submitButtonClicked="submitButtonClicked"
      :serverType="selectedServer.type"
      @set-os-or-template-select="setOsOrTemplateSelect"/>

      <!--   Additional Article Data-->
      <div v-if="hasZusatzasrtikel">
        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; margin-top: 10px;" >
          <span style=" text-align: left; margin-left: 2px; font-weight: bold;">{{'Zusatz Artikel'}}</span>&nbsp;
          <a-switch v-model:checked="checkArticles" @change="resetAdditionalArticles()" style=" text-align: right; margin-right: 2px; margin-bottom: 10px;"></a-switch>
        </div>
        <div v-if="checkArticles" style="background-color: #fff; color: #000; margin-bottom: 10px; border-radius: 6px;">
          <ConfigArticleFormField
              v-for="(field, j) of articlesFields"
              :key="j"
              :options="field"
              :isResource="false"
              :submitButtonClicked="submitButtonClicked"
              :serverType="selectedServer.type"
          />
        </div>
      </div>

      <div>
        <a-button
            type="primary"
            @click="createServer"
            class="create-server-submit"
        >
          <img src='../../assets/click-arrow.svg' width="19"/>
          <strong style="color: #fff"> Erstellen</strong>
        </a-button>
        <br/>
        <span v-if="((requireCpu || requireRam || requireStorage || requireOS ) && submitButtonClicked)" style="color: red; font-weight: bold;">Mindestanfordungen zum Erstellen eines Cloud-Servers: CPU, RAM, Storage und ein Betriebssystem/Template</span>
      </div>
    </div>
    <!-- Config for Resources in Sidebar for Collaboration & Cloud Speicher -->
    <div class="settings-container" v-if="selectedServer.type === 'wpCloud'">
      <span v-if="validateServerName && submitButtonClicked" style="color: red; font-weight: bold;">Ein gültiger Name besteht aus Zahlen, Buchstaben und &_. und besteht aus maximal 28 Zeichen!</span>
      <div style="display: flex; flex-direction: row; justify-content: flex-start;">
        <a-popover :title="'Standard-Einstellung:'">
          <template #content>
            <span>{{'Die Standard-Einstellungen sind nicht änderbar und'}}</span><br>
            <span>{{'werden bei jedem Cloud-Speicher mit berechnet.'}}</span>
          </template>
          <ExclamationCircleOutlined />
        </a-popover>
        <div style="margin-left: 5px; font-weight: bold;">Standard-Einstellungen:</div>
      </div>

      <ConfigFormField
        v-for="(field, j) of numberFields"
        :key="j"
        :options="field"
        :isResource="true"
        :submitButtonClicked="submitButtonClicked"
        :serverType="selectedServer.type"
      />
      <ConfigOsList
      :serverType="selectedServer.type"/>

      <!--   Additional Article Data-->
      <div v-if="hasZusatzasrtikel">
        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; margin-top: 10px;" >
          <span style=" text-align: left; margin-left: 2px; font-weight: bold;">{{'Zusatz Artikel'}}</span>&nbsp;
          <a-switch v-model:checked="checkArticles" @change="resetAdditionalArticles()" style=" text-align: right; margin-right: 2px; margin-bottom: 10px;"></a-switch>
        </div>
        <div v-if="checkArticles" style="background-color: #fff; color: #000; margin-bottom: 10px; border-radius: 6px;">
          <ConfigArticleFormField
              v-for="(field, j) of articlesFields"
              :key="j"
              :options="field"
              :isResource="false"
              :submitButtonClicked="submitButtonClicked"
              :serverType="selectedServer.type"
          />
        </div>
      </div>

      <div >
        <div>
          <a-button
            type="primary"
            @click="createServer"
            class="create-server-submit"
          >
            <img src='../../assets/click-arrow.svg' width="19"/>
            <strong style="color: #fff"> Erstellen</strong>
          </a-button>
          <br/>
          <span v-if="((requireCpu || requireRam || requireStorage) && submitButtonClicked)" style="color: red; font-weight: bold;">Mindestanfordungen zum Erstellen eines Cloud-Servers: CPU, RAM und Storage</span>
        </div>
      </div>
    </div>
    <!-- Config for Resources in Sidebar for Cyber Cloud Endpoint (Backup & Security) -->
    <div class="settings-container" v-if="selectedServer.type === 'cybercloudendpoint'">
      <span v-if="validateServerName && submitButtonClicked" style="color: red; font-weight: bold;">Ein gültiger Name besteht aus Zahlen, Buchstaben und &_. und besteht aus maximal 28 Zeichen!</span>
      <div style="display: flex; flex-direction: row; justify-content: center;">
        <a-popover :title="'Endpoint-Auswahl:'">
          <template #content>
            <span>{{'Sie haben die Möglichkeit einen einzelnen Endpoint oder direkt'}}</span><br>
            <span>{{'eine gesamte Gruppe aus mehreren Endpoints zu konfigurieren.'}}</span>
          </template>
          <ExclamationCircleOutlined />
        </a-popover>
        <div style="margin-left: 5px; font-weight: bold;">Endpoint-Auswahl:</div>
      </div>

      <!-- <CyberCloudFormField/> -->
      <CyberCloudFormField
      :serverType="selectedServer.type"/>

      <!--   Additional Article Data-->
      <div v-if="hasZusatzasrtikel">
        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; margin-top: 10px;" >
          <span style=" text-align: left; margin-left: 2px; font-weight: bold;">{{'Zusatz Artikel'}}</span>&nbsp;
          <a-switch v-model:checked="checkArticles" @change="resetAdditionalArticles()" style=" text-align: right; margin-right: 2px; margin-bottom: 10px;"></a-switch>
        </div>
        <div v-if="checkArticles" style="background-color: #fff; color: #000; margin-bottom: 10px; border-radius: 6px;">
          <ConfigArticleFormField
              v-for="(field, j) of articlesFields"
              :key="j"
              :options="field"
              :isResource="false"
              :submitButtonClicked="submitButtonClicked"
              :serverType="selectedServer.type"
          />
        </div>
      </div>

      <div >
        <a-button
            type="primary"
            @click="createServer"
            class="create-server-submit"
        >
          <img src='../../assets/click-arrow.svg' width="19"/>
          <strong style="color: #fff"> Erstellen</strong>
        </a-button>
      </div>
    </div>
    <div class="settings-container" v-if="selectedServer.type.includes('container')">
      <span v-if="validateServerName && submitButtonClicked" style="color: red; font-weight: bold;">Ein gültiger Name besteht aus Zahlen, Buchstaben und &_. und besteht aus maximal 28 Zeichen!</span>
      <ContainerFormField
        :requireReplica="requireReplica"
        :requireCpu="requireCpu"
        :requireRam="requireRam"
        :requireStorage="requireStorage"
        :submitButtonClicked="submitButtonClicked"
        />

      <!--   Additional Article Data-->
      <div v-if="hasZusatzasrtikel">
        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; margin-top: 10px;" >
          <span style=" text-align: left; margin-left: 2px; font-weight: bold;">{{'Zusatz Artikel'}}</span>&nbsp;
          <a-switch v-model:checked="checkArticles" @change="resetAdditionalArticles()" style=" text-align: right; margin-right: 2px; margin-bottom: 10px;"></a-switch>
        </div>
        <div v-if="checkArticles" style="background-color: #fff; color: #000; margin-bottom: 10px; border-radius: 6px;">
          <ConfigArticleFormField
              v-for="(field, j) of articlesFields"
              :key="j"
              :options="field"
              :isResource="false"
              :submitButtonClicked="submitButtonClicked"
              :serverType="selectedServer.type"
          />
        </div>
      </div>

      <div>
        <a-button
            type="primary"
            @click="createServer"
            class="create-server-submit"
        >
          <img src='../../assets/click-arrow.svg' width="19"/>
          <strong style="color: #fff"> Erstellen</strong>
        </a-button>
        <br/>
        <span v-if="((requireReplica || requireCpu || requireRam || requireStorage) && submitButtonClicked)" style="color: red; font-weight: bold;">Mindestanfordungen zum Erstellen eines Containers: Replicas, CPU, RAM und Storage</span>
      </div>
    </div>

    <a-modal
      :title="'Konfiguration abbrechen?'"
      v-model:open="cancelConf"
      :closable="true"
      @cancel="cancelConf = false"
      @ok="closeSidebar()"
      okText="Ok"
      cancelText="Abbrechen"
      centered>
      Wollen Sie die Konfiguration wirklich abbrechen?
    </a-modal>
  </div>
</template>

<script setup>

//IMPORTS
import ConfigFormField from "./ConfigFormField.vue";
import CyberCloudFormField from "./CyberCloudFormField.vue";
import ConfigOsList from "./ConfigOsList.vue";
import ContainerFormField from "./ContainerFormField.vue"
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { ref, computed, toRaw } from 'vue'
import store from '@/store'
import ConfigArticleFormField from "@/components/forms/ConfigArticleFormField.vue";

//VARIABLES
const cancelConf = ref(false)
const isTemplate = ref(false)
const submitButtonClicked = ref(false)
const serverNameRegex = /^[\u00C0-\u017Fa-zA-Z0-9_.& ]*$/

//COMPUTED PROPERTIES
const selectedServer = computed(() => store.getters.selectedServer);
const isSidebarOpen = computed(() => store.getters.isSidebarOpen);
const checkArticles = computed ( {
  get () {
    return store.getters.getCheckArticles
  },
  set(value) {
    store.commit('setCheckArticles', value)
  }
})
const serverName = computed({
  get() {
    return selectedServer.value.name;
  },

  set(value) {
    let server = selectedServer.value;
    server.name = value;
  },
})

const requireReplica = computed(() => {
  if (selectedServer.value.type == 'container' || selectedServer.value.type == 'containerIaaS') {
    if (parseInt(selectedServer.value.configuration.Replicas) <= 0) {
      return true
    }
  }
  return false
})
const requireOS = computed (() => {
  if (selectedServer.value.type == 'iaas' || selectedServer.value.type == 'cloudServer') {
    if (selectedServer.value.configuration.os === "" || selectedServer.value.configuration.os === undefined) {
      return true
    }
  }
  return false
})
const requireCpu = computed(() => {
  if (selectedServer.value.type == 'container' || selectedServer.value.type == 'containerIaaS') {
    if (parseInt(selectedServer.value.configuration.container_cpu) <= 0) {
      return true
    }
  } else {
      if (parseInt(selectedServer.value.configuration.vCPU) <= 0) {
        return true
      }
  }
  return false
})

const requireRam = computed(() => {
  if (selectedServer.value.type == 'container' || selectedServer.value.type == 'containerIaaS') {
    if (parseInt(selectedServer.value.configuration.container_memory) <= 0 || selectedServer.value.configuration.container_memory === undefined) {
      return true
    }
  } else {
      if (parseInt(selectedServer.value.configuration.MEMORY) <= 0) {
        return true
      }
  }
  return false
})

const requireStorage = computed(() => {
  if (selectedServer.value.type == 'container' || selectedServer.value.type == 'containerIaaS') {
    if (parseInt(selectedServer.value.configuration.container_storage) <= 0 || selectedServer.value.configuration.container_storage === undefined) {
      return true
    }
  } else {
      if (parseInt(selectedServer.value.configuration.SSD) <= 0 && parseInt(selectedServer.value.configuration.HDD) <= 0) {
        return true
      }
  }
  return false
})

const validateServerName = computed(() => {
  if (!serverNameRegex.test(selectedServer.value.name) || selectedServer.value.name == ""){
    return true
  }
  return false
})

const numberFields = computed(() => {
  const original = store.getters.getPriceList
  var clone = new Map(original)
  for (var [key, value] of clone.entries()) {
    if (value.isLicense || key.includes('container_') || key.includes('k8s_')) {
      clone.delete(key)
    }
  }
  var sortedClone = new Map([...clone.entries()].sort((a, b) => toRaw(a[1]).order - toRaw(b[1]).order))
  return sortedClone
})

const articlesFields = computed(() => {
  const original = store.getters.getPriceList
  var clone = new Map(original)
  for (var [key, value] of clone.entries()) {
    if (value.type !== 'Zusatz-Artikel') {
      clone.delete(key)
    }
    if (!value.data.status || value.data.removed !== null) {
      clone.delete(key)
    }
  }
  return clone
})

const hasZusatzasrtikel = computed(() => {
  const original = store.getters.getPriceList
  var clone = new Map(original)
  for (var [key, value] of clone.entries()) {
    if (value.type === 'Zusatz-Artikel' && value.data.removed === null) {
      return true
    }
  }
  return false
})
//FUNCTIONS
function setOsOrTemplateSelect (value) {
  isTemplate.value = value
}
const addServer = () => {
  store.commit('addServer');
};

const resetServer = () => {
  store.commit('resetServer');
};

const setSelectedServer = (server) => {
  store.commit('setSelectedServer', server);
};

const setSidebarVisibility = (isVisible) => {
  store.commit('setSidebarVisibility', isVisible);
};

function resetAdditionalArticles (value) {
 if (!value) {
   for (var item of store.getters.getPriceList) {
     if ( item[1].type ==='Zusatz-Artikel') {
       selectedServer.value.configuration[item[0]] = 0
     }
   }
 }
}

function createServer() {
  submitButtonClicked.value = true

  if (requireCpu.value || requireRam.value || requireStorage.value || requireReplica.value || requireOS.value) {
    if (selectedServer.value.type === 'iaas' || selectedServer.value.type === 'cloudServer') {
      document.getElementById('isResourceInputContainer').scrollIntoView({ behavior: "smooth" });
    }
    return
  }
  if (validateServerName.value) {
    if (selectedServer.value.type === 'iaas' || selectedServer.value.type === 'cloudServer') {
      document.getElementById('cloudServerName').scrollIntoView({ behavior: "smooth" })
    }
    if (selectedServer.value.type === 'wpCloud') {
      document.getElementById('collaborationServerName').scrollIntoView({ behavior: "smooth" })
    }
    if (selectedServer.value.type === 'container') {
      document.getElementById('containerServerName').scrollIntoView({ behavior: "smooth" })
    }
    if (selectedServer.value.type === 'cybercloudendpoint') {
      document.getElementById('endpointServerName').scrollIntoView({ behavior: "smooth" })
    }
    return
  }
  submitButtonClicked.value = false
  if (selectedServer.value.type === 'iaas' || selectedServer.value.type === 'cloudServer') {
    if (!isTemplate.value) {
      selectedServer.value.templateUuid = "notSet"
    }
  }
  setSidebarVisibility(false);
  addServer();
  setSelectedServer({});
  store.commit('setCheckArticles', false)
}

function closeSidebar() {
  setSidebarVisibility(false);
  resetServer();
  cancelConf.value = false
  store.commit('setCheckArticles', false)
}

</script>

<style lang="scss">

.kdz-config-form {
  width: 100%;
  height: auto;

  input[type="number"] {
    padding: 0.2rem 0.5rem;
  }

}

.settings-container {
  position: relative;
  text-align: left;
  margin-bottom: 5rem;
}

.save-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 1rem 2rem;
  margin: 0;
  z-index: 5;
}
.serverNameValidation {
  border-color: red;
  border-width: 2px;
}

@media (min-width: 768px) {
  /*.kdz-config-form {
    position: sticky;
    position: -webkit-sticky;
    top: 110px;
    height: 700px;
  }*/

  .save-button {
    margin: 1rem;
    position: relative;
    bottom: auto;
    top: auto;
    left: auto;
    right: auto;
    padding: 0;
  }

  .settings-container {
    max-height: 75%;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
  }
}
.create-server-submit{
  text-align: center;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
}
</style>
