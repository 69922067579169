<template>
  <div class="panel-server">
    <div>
      <span class="server-name">{{ server.name}} </span>
      <div>
        <div class="clickable"  @click="editServer" style="display: flex; align-items: center; margin-top: 5px;">
          <img style="margin-right: 4px;" width="16" src='../../assets/computer.svg'/>
          Bearbeiten
        </div>
      </div>
      <div >
        <div class="clickable" @click="deleteServerModal = true" style="display: flex; align-items: center;">
          <img width="16" style="margin-right: 4px;" src='../../assets/trash-2.svg'/>
          Löschen
        </div>
      </div>
    </div>
    <a-modal
        :title="'Server löschen?'"
        v-model:open="deleteServerModal"
        :closable="true"
        @cancel="deleteServerModal = false"
        @ok="deleteConfig()"
        okText="Ok"
        cancelText="Abbrechen"
        centered>
        Wollen Sie den Server wirklich löschen?
      </a-modal>
  </div>
</template>

<script setup>

//IMPORTS
import { ref, computed } from 'vue';
import { dialog, imageUrl } from "@/mixins/mixins.js";
import store from '@/store'

//VARIABLES
const deleteServerModal = ref(false);

//PROPS
const props = defineProps({
    server: {
      type: Object,
      required: true,
    }
  })

//COMPUTED PROPERTIES
const capacity = computed(() => props.server.configuration.capacity)
const priceList = computed(() => store.getters.getPriceList)
const server = computed(() => props.server)

//FUNCTIONS
function deleteConfig() {
  store.commit("deleteServer", props.server.id);
  store.commit("setSidebarVisibility", false);
  deleteServerModal.value = false;
}

function editServer() {
  if(checkIfAnyAdditionalArticle()){
    store.commit('setCheckArticles', true)
  }
  store.commit("setSelectedServer", props.server);
  store.commit("setSidebarVisibility", true);
}

function checkIfAnyAdditionalArticle(){
  let dataName = ""
  for (let item of priceList.value) {
    for (const [key, value] of Object.entries(server.value.configuration)){
      dataName = item[0].replace(" ","_")
      if (item[1].type === 'Zusatz-Artikel' && dataName === key && server.value.type === "cybercloudendpoint" && value >0){
        return true
      }
    }
  }
  return false
}

Object.assign(window, dialog, imageUrl)
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}
.panel-server {
  //background: url("../../assets/panel.svg") no-repeat;
  width: 260px;
  padding: 10px;
  z-index: 5;
  position: relative;
  display: flex;
  background: #fff;
  border: 1px solid #3d7caf;
  border-bottom-width: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  //padding: 8px;
  margin-bottom: 10px;
  .server-name {
    font-weight: 600;
    font-size: 15px;
  }
  .clickable {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

@media (min-width: 1200px) {
  .panel-server {
    width: 2200px;
    padding: 10px;
    //height: 100px;
  }
}
@media (max-width: 1199px) {
  .panel-server {
    width: 170px;
    padding: 10px;
    //height: 100px;
  }
}
</style>
