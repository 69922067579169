import { createStore } from 'vuex'
import { toRaw } from 'vue'

const state = {
  accountId: "",
  customerId: undefined,
  customerName: undefined,
  isAuthenticated: false,
  status: "",
  email: "",
  configImportId: undefined,
  importedConfigName: undefined,
  importedConfigNumber: undefined,
  currentMenuTab: ['0'],
  otpValidated: false,
  sidebarOpen: false,
  firewall: false,
  firewall2: false,
  send: false,
  ip: 0,
  cloudServerIpCount: false,
  server: [],
  selectedServer: {},
  selectedNetwork: {},
  serverCopy: {},
  priceList: [],
  vkPriceList: [],
  customVkPrices: [],
  discountType: null,
  partnerDiscount: null,
  originalDiscountType: null,
  networkList: [1],
  networkObjectList : [],
  workOnOffer: false,
  network: [],
  templateRootDiskSize: 0,
  oldTemplateRootDiskSize: 0,
  zusatzArticleBool: false
};

const getters = {
  getAccountUuid: state =>state.accountId,
  getCustomerId: state => state.customerId,
  getCustomerName: state => state.customerName,
  getCustomVkPrices:  state => state.customVkPrices,
  getStatus: state => state.status,
  getCheckArticles: state => state.zusatzArticleBool,
  currentMenuTab: state => state.currentMenuTab,
  getConfigImportId: state => state.configImportId,
  getImportedConfigName: state => state.importedConfigName,
  getImportedConfigNumber: state => state.importedConfigNumber,
  getNetwork: state => state.network,
  getNetworkObjectList: state => state.networkObjectList,
  getOtpValidated: state => state.otpValidated,
  getPriceList: state => state.priceList,
  getVkPriceList: state => state.vkPriceList,
  getTemplateRootDiskSize: state => state.templateRootDiskSize,
  firewall: state => state.firewall,
  firewall2: state => state.firewall2,
  ip: state => state.ip,
  send: state => state.send,
  isSidebarOpen: state => state.sidebarOpen,
  selectedServerId: state => state.selectedServer.id,
  selectedServer: state => state.selectedServer,
  selectedNetwork: state => state.selectedNetwork,
  serverList: state => state.server,
  partnerDiscount: (state) => state.partnerDiscount,
  serverById: state => id => state.server.find(el => el.id === id),
  selectedConfiguration: state => state.selectedServer.configuration,
  serversByType: state => type => state.server.filter(el => el.type === type),
  bill: (state) => createBill(state),
  discountType: (state) => state.discountType,
  originalDiscountType: (state) => state.originalDiscountType,
  networkList : (state) => state.networkList,
  discount: (state, getters) => {
    let sum = 0.0;
    getters.bill.forEach((el) => {
      for (let pos of el.bill) {
        sum += Number(pos.price);
      }
    });
    let discount = 0;

    if (state.discountType) {
      discount = 20;
      if (sum >= 1000 || state.discountType === 'Bronze') {
        discount = 30;
      }
      if (sum >= 3000 || state.discountType === 'Silber') {
        discount = 35;
      }
      if (sum >= 7000 || state.discountType === 'Gold') {
        discount = 40;
      }
      if (sum >= 15000 || state.discountType === 'Platin') {
        discount = 47;
      }
    }
    return discount;
  },
  isWorkingOnOffer: state => state.workOnOffer,
};

const actions = {};

const mutations = {
  setAccountUuid: (state, input) => state.accountId = input,
  setCustomerId: (state, input) => state.customerId = input,
  setCustomerName: (state, input) => state.customerName = input,
  setCustomVkPrices: (state, input) => {
    var foundItem = state.customVkPrices.filter(item => item.id === input.id)[0]
    if (foundItem === undefined) {
      state.customVkPrices.push(input)
    } else {
      foundItem.customPrice = input.customPrice
    }
  },
  overWriteCustomVkPrices:(state, input) => {
    state.customVkPrices = input
  },
  setAuthentification: (state, loginInput) => state.isAuthenticated = loginInput,
  setStatus (state, loginInput) {
    state.status = loginInput
  },
  setCurrentMenuTab: (state, count) => {
    state.currentMenuTab = count
  },
  setCheckArticles: (state, input) => state.zusatzArticleBool = input,
  setConfigImportId: (state, input) => state.configImportId = input,
  setImportedConfigName: (state, input) => state.importedConfigName = input,
  setImportedConfigNumber: (state, input) => state.importedConfigNumber = input,
  setOtpValidated (state,valid){
      state.otpValidated = valid
  },
  setPriceList (state, data) {
    state.priceList = data
  },
  setVkPriceList (state, data) {
    state.vkPriceList = data
  },
  setFirewall: (state, status) => {
    state.firewall = status
  },
  setFirewall2: (state, status) => {
    state.firewall2 = status
  },
  setServerList:(state, status) => {
    state.server = status
  },
  setSend: (state, status) => state.send = status,
  setIp: (state, count) => state.ip = count,
  pushNetworkList: (state, count) => {
    state.networkList.push(count)
  },
  setNetworkList: (state, count) => state.networkList = count,
  setSidebarVisibility: (state, visible) => state.sidebarOpen = visible,
  setDiscountType: (state, discountType) => state.discountType = discountType,
  setOriginalDiscountType: (state, originalDiscountType) => state.originalDiscountType = originalDiscountType,
  setPartnerDiscount: (state, partnerDiscount) => state.partnerDiscount = partnerDiscount,
  setSelectedServer: (state, server) => {
    state.selectedServer = server;
    state.serverCopy = JSON.parse(JSON.stringify(server));
  },
  setTemplateRootDiskSize: (state, count) => state.templateRootDiskSize = count,
  addServer: (state) => {
    state.templateRootDiskSize = 0
    let index = state.server.findIndex(el => el.id === state.selectedServer.id);
    if (index > -1) {
      state.server[index] = state.selectedServer;
    } else {
      if (state.selectedServer.type !== "wpCloud"){
        state.selectedServer.name += (" " + (state.server.filter(server => server.type == state.selectedServer.type).length + 1));
      }
      if (state.selectedServer.type === "cloudServer" || state.selectedServer.type === "iaas") {
        state.selectedServer.templateUuid = "notSet"
      }
      state.server.push(state.selectedServer)
    }

  },
  setSelectedConfiguration: (state, config) => {
    state.selectedServer.configuration = config
  },
  resetServer: state => {
    state.templateRootDiskSize = 0
    state.zusatzArticleBool = false
    state.selectedServer = {}
    let index = state.server.findIndex(el => el.id === state.serverCopy.id)
    if (index > -1) {
      state.server[index] = state.serverCopy
      state.serverCopy = {}
    }
    state.server = [...state.server.filter(el => {
      let config = Object.assign({}, el.configuration)
      if(el.type == 'network'){
        return Object.values(config)
      }
      delete config.network
      return Object.values(config).filter(elem => elem != 0).length > 0
    })];
  },
  clearServer: state => {
    state.server = []
    state.firewall = false
    state.firewall2 = false
    state.selectedServer = {}
    state.ip = 0
    state.templateRootDiskSize = 0
    state.configImportId = undefined
    state.importedConfigName = undefined
    state.importedConfigNumber = undefined
    state.customerId = undefined
    state.customerName = undefined
    state.networkList = [1]
    state.customVkPrices = []
    state.networkObjectList = []
  },
  deleteServer: (state, id) => {
    state.templateRootDiskSize = 0
    state.server = [...state.server.filter(el => el.id !== id)];
  },
  deleteNetworkServer: (state, networkId) => {
    state.server = [...state.server.filter(el => {
      if (el.type !== "iaas") return true;
      return el.configuration.network !== networkId;
    })]
  },
  setWorkingOnOffer: (state, status) => {
    state.workOnOffer = status
  },
  setNetwork(state, networkData) {
    state.network = networkData
  },
  setNetworkObjectList: (state, network) => state.networkObjectList = network,
  pushNetworkObjectList: (state, networkData) => {
    state.networkObjectList.push(networkData)
  },
  clearNetworkList(state) {
    state.networkList = []; // Liste leeren
  },
};


//---Functions for the Prices--
function createBill(state) {
  let bill = [];
  let ipCount = 0
  state.cloudServerIpCount = false
  for (let server of state.server) {
    switch (server.type) {
      case "container":
      case "iaas":
      case "containerIaaS":
      case "cloudServer":
        if (ipCount > 0) {
          state.cloudServerIpCount = true
        }
        ipCount += parseInt(server.configuration['IP_ADDRESS'])
        if (Object.keys(server.configuration).length > 0)
          bill.push(createBillData(server, state.priceList, ipCount));
        break;
      case "cybercloudendpoint":
        if (Object.keys(server.configuration).length > 0)
          bill.push(createBillData(server, state.priceList, 0));

          for (let i = 0; i < bill.length; i++) {
            if (bill[i].bill.length>1) {
              if (bill[i].bill[0].label.includes('Backup-Speicher in GB')) {
                let temp = []
                temp.push(bill[i].bill[1])
                bill[i].bill[1] = bill[i].bill[0]
                bill[i].bill[0] = temp[0]
              }
            }
          }
        break;
      case "wpCloud":
        if (Object.keys(server.configuration).length > 0)
          bill.push(createBillData(server, state.priceList, 0))
        break;
      case "network":
        if (Object.keys(server).length > 0)
          bill.push(createBillData(server, state.priceList, 0))
        break;
      default:
        break;
    }
  }
  if (state.firewall || state.firewall2) {
    var fireWallObject = state.priceList.get('NextGenerationFirewall')
    if (state.discountType !== state.originalDiscountType){
      var price = parseFloat((state.firewall && state.firewall2 ? 2 : 1) * ((state.priceList.get('NextGenerationFirewall').priceWithoutDiscount / 100) * (100-parseInt(state.partnerDiscount))))
    } else {
      var price = parseFloat((state.firewall && state.firewall2 ? 2 : 1) * (state.priceList.get('NextGenerationFirewall').price))
    }
    var customVkPrice = state.customVkPrices.filter(x => x.id === fireWallObject.data.usageType)[0]
    var vkSinglePrice = (customVkPrice !== undefined && toString(customVkPrice.customPrice) !== fireWallObject.vk_price) ? customVkPrice.customPrice : fireWallObject.vk_price
    bill.unshift({
      serverName: "Next-Generation-Firewall",
      id: "firewall",
      bill: [{
        resourceId: state.priceList.get('NextGenerationFirewall').data.tagId === 0 ? state.priceList.get('NextGenerationFirewall').data.usageType : state.priceList.get('NextGenerationFirewall').data.tagId,
        price: price.toFixed(3),
        discount: state.partnerDiscount,
        originalPrice: parseFloat(state.priceList.get('NextGenerationFirewall').priceWithoutDiscount * (state.firewall && state.firewall2 ? 2 : 1)),
        vkSinglePrice: vkSinglePrice,
        originalVkPrice: parseFloat(vkSinglePrice * (state.firewall && state.firewall2 ? 2 : 1)),
        label: "Firewall x" + (state.firewall && state.firewall2 ? 2 : 1),
        count: (state.firewall && state.firewall2 ? 2 : 1) ,
        labelPdf: "Next-Generation-Firewall",
        singlePrice: price.toFixed(3),
        hoverLabel: 'NextGenerationFirewall',
        UsageForm: '('
      }]
    })
  }

  if (state.ip > 0) {
    var ipObject = state.priceList.get('IP_ADDRESS')
    var ipPrice = parseFloat((ipObject.priceWithoutDiscount / 100) * (100-parseInt(state.partnerDiscount)))

    if (state.discountType !== state.originalDiscountType){
      var singlePrice = ipPrice
      var price = (parseFloat(ipPrice) * state.ip) - parseFloat(ipPrice);
    } else {
      var singlePrice = ipObject.price
      var price = (parseFloat(ipObject.price) * state.ip) - parseFloat(ipObject.price);
    }
    var customVkPrice = state.customVkPrices.filter(x => x.id === ipObject.data.usageType)[0]
    var vkSinglePrice = (customVkPrice !== undefined && toString(customVkPrice.customPrice) !== ipObject.vk_price) ? customVkPrice.customPrice : ipObject.vk_price
    bill.unshift({
      serverName: "Öffentliche IP-Adressen",
      id: "ip",
      bill: [{
        resourceId: state.priceList.get('IP_ADDRESS').data.tagId === 0 ? state.priceList.get('IP_ADDRESS').data.usageType : state.priceList.get('IP_ADDRESS').data.tagId,
        price: price.toFixed(3),
        discount: state.partnerDiscount,
        originalPrice: (parseFloat(state.priceList.get('IP_ADDRESS').priceWithoutDiscount) * state.ip) - (ipCount > 0 ? 0 : parseFloat(state.priceList.get('IP_ADDRESS').priceWithoutDiscount)),
        vkSinglePrice: vkSinglePrice,
        originalVkPrice: (parseFloat(vkSinglePrice) * state.ip) - (parseFloat(vkSinglePrice)),
        label: "IP-Adressen x" + state.ip,
        count: state.ip,
        labelPdf: "Öffentliche IP-Adressen",
        singlePrice: singlePrice/* state.ip > 1 ?(price/(state.ip-1)) : parseFloat(ipPrice) */,
        hoverLabel: "Öffentliche IP",
        UsageForm: '(pro IP, '
      }]
    })
  }
  // sort Bill Arrays in this parent BIll obj alphabeically to display nicely in the costOverview
  for (var item of bill) {
    if (item.bill.length > 0) {
      item.bill.sort((a, b) => a.hoverLabel.localeCompare(b.hoverLabel))
    }
  }
  return bill;
}

function createBillData(server, prices, ipCount) {
  let os = (server.configuration.os !== "" && server.configuration.os !== undefined && server.configuration.os !== "Andere") ?  prices.get(server.configuration.os).label  : "";
  let network = "network" in server.configuration ? "Netzwerk " + server.configuration.network : "";
  let billPos = {
    serverName: server.type === 'iaas' || server.type === 'cloudServer' ? getServerName(server) +":" +
    (network !== "" ? "\n<small>("+network+(os !== "" ? ", "+os+"" : "")+")</small>" : (os !== "" ? "\n<small>("+os+")</small>" : "")):
    (server.type === 'container' || server.type === 'containerIaaS' ? getServerName(server)+ ":"+"\n<small>(" +
    (network !== "" ? network + ", " : "") +
    (server.configuration["Replicas"] === 1 ? "1 Replica" : (server.configuration["Replicas"] + " Replicas")) +
    ")</small>" : getServerName(server)+ ":"),
    id: server.id,
    netId: server.netId,
    bill: [],
    type: server.type,
    templateUuid: server.templateUuid
  };

  if (prices !== undefined) {
    for (let [key, pos] of prices) {
      if (pos.label === "firewall" || pos.label === "network") continue;
      var resourceId
      var calcPrice
      var originalPrice
      var calcLabel
      var discount
      var hoverLabel
      var singlePrice
      var vkSinglePrice
      var originalVkPrice
      var perUsageForm
      if (pos.label.includes("Kern") || pos.label.includes("Core")) {
        perUsageForm = '(pro Kern, '
      } else if (pos.label.includes("in GB")){
        perUsageForm = '(pro GB, '
      } else if (pos.label.includes("IP-Adressen") || pos.label.includes("Öffentliche IP")) {
        perUsageForm = '(pro IP, '
      } else if (pos.label.includes("Firewall") || pos.label.includes("GPU")) {
        perUsageForm = '('
      } else {
        perUsageForm = '(pro Lizenz, '
      }
      singlePrice = pos.price
      // var customVKPrice = undefined
      if (pos.label !== "LibreOffice Lizenz" && pos.label !== "Linux") {
        if (pos.data.tagId === 0) {
          var customVkPrice = state.customVkPrices.filter(x => x.id === pos.data.usageType)[0]
        } else {
          // special case for Windows 10 (tagId 3) becuase it has the same id as IP (UsageType 3) and others
          var specialIdInput = 0
          if (pos.data.tagId === 3) {
            specialIdInput = 8888888
          } else if (pos.data.tagId === 17) {
            specialIdInput = 8888817
          } else if (pos.data.tagId === 66) {
            specialIdInput = 8888866
          } else if (pos.data.tagId === 67) {
            specialIdInput = 8888867
          } else if (pos.data.tagId === 68) {
            specialIdInput = 8888868
          } else {
            specialIdInput = pos.data.tagId
          }
          var customVkPrice = state.customVkPrices.filter(x => x.id === specialIdInput)[0]
        }
        vkSinglePrice = (customVkPrice !== undefined && toString(customVkPrice.customPrice) !== pos.vk_price) ? customVkPrice.customPrice : pos.vk_price
      } else {
        vkSinglePrice = 0
      }
      var specialIdInput2 = 0
      if (pos.data.tagId === 3) {
        specialIdInput2 = 8888888
      } else if (pos.data.tagId === 17) {
        specialIdInput2 = 8888817
      } else if (pos.data.tagId === 66) {
        specialIdInput2 = 8888866
      } else if (pos.data.tagId === 67) {
        specialIdInput2 = 8888867
      } else if (pos.data.tagId === 68) {
        specialIdInput2 = 8888868
      } else {
        specialIdInput2 = pos.data.tagId
      }
      // special case for Windows 10 (tagId 3) becuase it has the same id as IP (UsageType 3) and others
      resourceId = pos.data.tagId === 0 ? pos.data.usageType : specialIdInput2
      switch (pos.label) {
        case 'Cyber Cloud Remote Backup':
          if ( server.type === 'iaas' || server.type === 'cloudServer') {
            calcPrice = ((pos.price * server.configuration['HDD']) + (pos.price * server.configuration['SSD']))
            calcLabel = pos.label + " x" + server.configuration[key]
            originalPrice = ((pos.price * server.configuration['HDD']) + (pos.price * server.configuration['SSD']))
            originalVkPrice = ((vkSinglePrice * server.configuration['HDD']) + (vkSinglePrice * server.configuration['SSD']))
            discount = pos.data.discount
            hoverLabel = 'Cyber Cloud Remote Backup'
            pos.label = 'Cyber Cloud Remote Backup'
            perUsageForm = '(pro GB an Storage, '
          } else if (server.type === 'cybercloudendpoint'){
            calcPrice = pos.price * server.configuration[key]
            calcLabel = 'Backup-Speicher in GB x' + server.configuration[key]
            originalPrice = pos.price * server.configuration[key]
            originalVkPrice = vkSinglePrice * server.configuration[key]
            discount = pos.data.discount
            hoverLabel = 'Backup-Speicher in GB'
            perUsageForm = '(pro GB, '
          }

          break
        case 'MSSQL Standard (Core License)':
          calcPrice = pos.price * (parseInt(server.configuration['vCPU']) %2 ? parseInt(server.configuration['vCPU']) + 1 : server.configuration['vCPU'])
          calcLabel = pos.label + " x" + (parseInt(server.configuration['vCPU']) %2 ? parseInt(server.configuration['vCPU']) + 1 : server.configuration['vCPU'])
          originalPrice = pos.price * (parseInt(server.configuration['vCPU']) %2 ? parseInt(server.configuration['vCPU']) + 1 : server.configuration['vCPU'])
          discount = pos.data.discount
          originalVkPrice = vkSinglePrice * (parseInt(server.configuration['vCPU']) %2 ? parseInt(server.configuration['vCPU']) + 1 : server.configuration['vCPU'])
          hoverLabel = pos.hoverLabel
          break
        case 'Container vCPU':
          calcPrice = pos.price * (parseInt(server.configuration['Replicas']) * parseInt(server.configuration['container_cpu']))
          calcLabel = pos.label + " x" + parseInt(server.configuration['container_cpu'])
          originalPrice = pos.price * (parseInt(server.configuration['Replicas']) * parseInt(server.configuration['container_cpu']))
          originalVkPrice = vkSinglePrice * (parseInt(server.configuration['Replicas']) * parseInt(server.configuration['container_cpu']))
          discount = pos.data.discount
          hoverLabel = pos.hoverLabel
          break
        case 'Container RAM':
          calcPrice = pos.price * (parseInt(server.configuration['Replicas']) * parseInt(server.configuration['container_memory']))
          calcLabel = pos.label + " x" +  parseInt(server.configuration['container_memory'])
          originalPrice = pos.price * (parseInt(server.configuration['Replicas']) * parseInt(server.configuration['container_memory']))
          originalVkPrice = vkSinglePrice * (parseInt(server.configuration['Replicas']) * parseInt(server.configuration['container_memory']))
          discount = pos.data.discount
          hoverLabel = pos.hoverLabel
          break
        default:
          calcPrice = pos.price * server.configuration[key]
          calcLabel = pos.label + " x" + server.configuration[key]
          originalPrice = pos.price * server.configuration[key]
          originalVkPrice = vkSinglePrice * server.configuration[key]
          discount = pos.data.discount
          hoverLabel = pos.hoverLabel
      }
      // TODO can be removed because this check is done in the backend?
      if (pos.isResource || pos.label.includes('GPU') || pos.label === 'TSPlus') {
        if (state.discountType !== state.originalDiscountType){
          discount = state.partnerDiscount
          calcPrice = ((pos.priceWithoutDiscount / 100) * (100-parseInt(state.partnerDiscount))) * server.configuration[key]
          singlePrice = ((pos.priceWithoutDiscount / 100) * (100-parseInt(state.partnerDiscount)))
        } else {
          discount = pos.data.discount
          calcPrice = pos.price * server.configuration[key]
        }
        originalPrice = pos.priceWithoutDiscount * server.configuration[key]
        originalVkPrice = vkSinglePrice * server.configuration[key]
        // still recalculate container resources with replica value
        if (pos.label === 'Container RAM' || pos.label === 'Container vCPU') {
          calcPrice = calcPrice * parseInt(server.configuration['Replicas'])
          originalPrice = originalPrice * parseInt(server.configuration['Replicas'])
          originalVkPrice = originalVkPrice * parseInt(server.configuration['Replicas'])
        }
      }
      let invoice = {
        resourceId: resourceId,
        label: calcLabel,
        price: (calcPrice).toFixed(3),
        discount: (typeof discount === 'string' || discount instanceof String) ? discount.split('.')[0] : discount,
        originalPrice: (originalPrice).toFixed(3),
        type: key,
        count: pos.label === 'MSSQL Standard (Core License)' ? (parseInt(server.configuration['vCPU']) %2 ? parseInt(server.configuration['vCPU']) + 1 : server.configuration['vCPU']) : server.configuration[key],
        labelPdf: pos.label,
        order: pos.order,
        hoverLabel:hoverLabel,
        UsageForm:perUsageForm,
        singlePrice:parseFloat(singlePrice).toFixed(3),
        vkSinglePrice: vkSinglePrice,
        originalVkPrice: originalVkPrice
      };
      if (key === "IP_ADDRESS" && server.type === 'iaas') {
        var ipPrice = (pos.priceWithoutDiscount / 100) * (100-parseInt(state.partnerDiscount))
        if (state.discountType !== state.originalDiscountType){
          invoice.price = ((ipPrice * server.configuration[key])-((state.ip > 0 || state.cloudServerIpCount === true) ? 0 : ipPrice)).toFixed(3)
        } else {
          invoice.price = (invoice.price - ((state.ip > 0 || state.cloudServerIpCount === true) ? 0 : pos.price)).toFixed(3);
        }
        invoice.originalPrice = ((server.configuration[key] * pos.priceWithoutDiscount) - pos.priceWithoutDiscount).toFixed(3);
        invoice.originalVkPrice = ((invoice.vkSinglePrice * server.configuration[key]) - ((state.ip > 0 || state.cloudServerIpCount === true) ? 0 : vkSinglePrice)).toFixed(3);

        // invoice.originalVkPrice = ((server.configuration[key] * vkSinglePrice) - vkSinglePrice).toFixed(3)
      }
      if (server.configuration[key] > 0 && server.configuration[key] !== "") billPos.bill.push(invoice);
    }
    if (server.configuration.firewall) {
      const firewall = {
        label: "Next-Generation-Firewall",
        // price: (prices.get('NextGenerationFirewall').price).toFixed(3),
        labelPdf: "Next-Generation-Firewall",
        count: (state.firewall && state.firewall2 ? 2 : 1),
        order: 9
      };
      billPos.bill.push(firewall);
    }
    return billPos;
  }
}
function getServerName(server) {
  let config = server.configuration;
  if (config.user > 0) {
    return "RDP-Server";
  } else if (config.sandbox > 0 || config.msExUser > 0) {
    return "MS Exchange Server";
  } else if (config.sqluser > 0) {
    return "MS SQL Server";
  }
  return server.name;

}

export default createStore({
  state,
  getters,
  actions,
  mutations
});


