<template>
  <a-modal
      v-if="openModal"
      style="top: 20px;"
      centered
      :title="'Artikel löschen'"
      :closable="false"
      :loading="loading"
      :maskClosable="false"
      v-model:open="openModal"
  >
    <div style="display: flex; justify-content: center;">
      <DeleteOutlined :style="{fontSize: '40px', color: 'red'}" />
      <span style="margin-left: 20px;"><span> {{ modalText() }}</span><span style="font-weight: bold;">{{ articleName }}</span><span>{{ ' wirklich löschen möchten?' }}</span></span>
    </div>
    <template #footer style="text-align: right;">
      <a-button id="deleteArticleCancel" type="default" @click="closeDeleteModal()">Abbrechen</a-button>
      <a-button id="deleteArticleOk" type="primary" :loading="loading" @click="deleteAdditionalArticle ()">Löschen!</a-button>
    </template>
  </a-modal>
</template>

<script setup>

import {DeleteOutlined} from "@ant-design/icons-vue";
import {ref, watch} from "vue";
import api from "@/apis/apis";
import {notification} from "ant-design-vue";

// VARIABlES
const loading   = ref(false)
const openModal = ref(false)
const emit = defineEmits(['reload-articles', 'close-modal']);

//PROPS
const props = defineProps ({
  deletionArticleModal: {
    type: Boolean,
    required: true
  },
  articleName: {
    type: String,
    required: true
  },
  articleUuid: {
    type: String,
    required: true
  }
})

// WATCH
watch (() => props.deletionArticleModal, function(newVal) {
  openModal.value = newVal
})

// FUNCTIONS
function closeDeleteModal () {
  openModal.value = false
  loading.value = false
  emit('close-modal')
}

function modalText () {
  return 'Sind Sie sich sicher, dass Sie den Artikel '
}

async function deleteAdditionalArticle () {

  let params = new FormData()
  params.append("articleUuid", props.articleUuid)

  await api.removeAdditionalArticles(params).then(response =>{
    emit('reload-articles')
    notification.success({
      message: 'Erfolg',
      description:
          'Das Artikel wurde erfolgreich gelöscht.',
      duration: 4
    })
  }).catch(error => {
    notification.error({
      message: 'Fehler beim Löschen des Artikels',
      description:
          'Das Artikel konnte nicht gelöscht werden, bitte versuchen Sie es erneut. (' + error.response.data + ')',
      duration: 0
    })
    
  })
  closeDeleteModal()
}

</script>

<style scoped lang="scss">

</style>
