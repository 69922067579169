<template>
  <div class="">
    <div :class="['kdz-height']" style="    position: relative;">
      <img :class="[
        'kdz-config-img',
        'server',
        { 'kdz-small': isSmallScreen },
      ]" src='../../assets/server_single.svg' v-if="!isConfigurationEmpty"/>
      <div>
        <transition-group name="fade" tag="div" v-if="!isSmallScreen" class="mainHeight">
          <div class="cpu-wrapper fade-in" v-if="configuration.vCPU > 0" :key="'vCPU'">
            <img class="kdz-config-img cpu" src='../../assets/cpu_core.png'/>
            <div class="bar-horizontal-cpu grey-bar"></div>
            <div class="bar-vertical-cpu grey-bar"></div>
          </div>
          <div class="ram-wrapper fade-in" v-if="configuration.MEMORY > 0" :key="'MEMORY'">
            <img class="kdz-config-img ram" src='../../assets/ram.png'/>
            <div class="bar-vertical-ram grey-bar"></div>
            <div class="bar-horizontal-ram grey-bar"></div>
          </div>
          <div class="ssd-wrapper fade-in" v-if="configuration.SSD > 0" :key="'SSD'">
            <img class="kdz-config-img ssd" src='../../assets/ssd.png'/>
            <div class="bar-horizontal-ssd grey-bar"></div>
          </div>
          <div class="hdd-wrapper fade-in" v-if="configuration.HDD > 0" :key="'HDD'">
            <img class="kdz-config-img hdd" src='../../assets/hdd.png'/>
            <div class="bar-vertical-hdd grey-bar"></div>
            <div class="bar-horizontal-hdd grey-bar"></div>
          </div>
          <div class="os-wrapper" v-if="configuration.os" :key="'os'">
            <img class="kdz-config-img os" :src="getImgUrl(osIcon)"/>
          </div>
          <div class="office-wrapper" :key="'office'">
            <img class="kdz-config-img office" src='../../assets/msoffice.svg'
                 v-if="configuration.MS_Office_2021_1_User > 0"/>
            <img class="kdz-config-img office" src='../../assets/libre.svg' v-if="configuration.libre > 0"/>
          </div>
        </transition-group>
      </div>
      <div v-if="isConfigurationEmpty">
        <img class="server-cloud" src='../../assets/create_server.png' :key="'cross'"/>

        <div id="addNewCloudServerBtn" class="panel panel-cloud clickable text-center" key="" @click="createServer">
          <div class="clickable">
            <img width="20" src='../../assets/add_cross.svg'/>
          </div>
          <span>Neuen Cloud Server erstellen</span>
        </div>
      </div>
      <div v-if="!isConfigurationEmpty" id="editDeleteServer" class="editCloudServer">
        <div class="editCloudServer-inner">
          <div>
            <span class="kdz-server-title" style="word-break: break-word;">{{ name }}</span>
          </div>
          <div class="clickable flexBetween" @click="editServer">
            <img src='../../assets/computer.svg' width="16" alt="" style="margin-right: 3px; margin-left: 3px; "/>
            <div style="word-break: break-word;"> {{ name }} bearbeiten</div>
          </div>
          <div class="clickable flexBetween" @click="deleteServerModal = true">
            <img src='../../assets/trash-2.svg' width="16" alt="" style="margin-right: 3px; margin-left: 3px;"/>
            <span style="word-break: break-word;"> {{ name }} löschen</span>
          </div>
          <div class="clickable flexBetween" @click="addNewServer()"
               v-if="isSidebarOpen === false && canCreateServer === true">
            <img src='../../assets/plus-solid.svg' width="16" alt="" style="margin-right: 3px; margin-left: 3px;"/>
            <span> Cloud Server erstellen</span>
          </div>
        </div>
      </div>
    </div>

    <div style="font-size: 14px;">
      Hinweis: Die Kostenrechnung basiert auf der tatsächlichen Laufzeit in Stunden: Festplatten und Lizenzen werden
      während der gesamten VM-Existenz abgerechnet, selbst im ausgeschalteten Zustand, während CPU und RAM nur für die
      aktive Betriebszeit berechnet werden.
    </div>
    <a-modal
        :title="'Server löschen?'"
        v-model:open="deleteServerModal"
        :closable="true"
        @cancel="deleteServerModal = false"
        @ok="deleteConfig()"
        okText="Ok"
        cancelText="Abbrechen"
        centered>
      Wollen Sie den Server wirklich löschen?
    </a-modal>
  </div>
</template>

<script setup>

//IMPORTS
import {ref, computed, onMounted, onBeforeUnmount} from 'vue'
import store from '@/store'

//VARIABLES
const deleteServerModal = ref(false)
const screenWidth = ref(1920)
const additionalArticlesCheckIfAny = ref(false)

const changeWidth = () => screenWidth.value = window.screen.width
const getImgUrl = (pic) => require('../../assets' + pic)

//PROPS
const props = defineProps({
  serverId: {
    type: Number,
    required: true,
  },
  isLast: {
    type: Boolean,
    default() {
      return false;
    },
  },
  canCreateServer: {
    type: Boolean,
  }
})

//COMPUTED PROPERTIES
const osIcon = computed(() => {
  if (configuration.value.os === "Andere") {
    return '/mitteldeutsche_logo_circle.svg'
  }
  let iconUrl = '/' + store.getters.getPriceList.get(configuration.value.os).image;

  if (configuration.value.MSSQL_Standard_User_License > 0 || configuration.value.MSSQL_Standard_Core_License > 0) {
    iconUrl = '/microsoft-sql-server.png';
  }

  if (configuration.value.MS_Exchange > 0) {
    iconUrl = '/exchange-new.svg'
  }

  return iconUrl;
})

const priceList = computed(() => store.getters.getPriceList)
const isSidebarOpen = computed(() => store.getters.isSidebarOpen)
const server = computed(() => store.getters.serverById(props.serverId))
const configuration = computed(() => server.value.configuration)
const name = computed(() => server.value.name)
const isConfigurationEmpty = computed(() => Object.keys(server.value.configuration).length === 0)
const selectedServerId = computed(() => store.getters.selectedServerId)
const isVisible = computed(() => selectedServerId.value === server.value.id || isConfigurationEmpty.value)
const isSmallScreen = computed(() => screenWidth.value <= 992)

//EMITS
const emit = defineEmits(['addServer', 'changeCreateServerButton', 'deleteServerButton'])

//LIFECYCLE HOOKS
onMounted(() => {
  changeWidth()
  emit('changeCreateServerButton');
  window.addEventListener("resize", changeWidth)
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", changeWidth)
})

//FUNCTIONS
function addNewServer() {
  emit('addServer');
  emit('changeCreateServerButton');

  setTimeout(() => {
    const btnElement = document.getElementById('addNewCloudServerBtn')
    if (btnElement !== null) btnElement.scrollIntoView({behavior: "smooth"})
  }, "200");
}

function createServer() {
  for (let item of priceList.value) {
    server.value.configuration[item[0]] = 0
  }
  server.value.configuration.os = ""
  editServer();
}

function editServer() {
  if(checkIfAnyAdditionalArticle()){
    store.commit('setCheckArticles', true)
  }
  emit('changeCreateServerButton');
  store.commit("setSelectedServer", server.value)
  store.commit("setSidebarVisibility", true)
  const containerElement = document.getElementById('productSelectionContainer')
  if (containerElement !== null) {
    containerElement.scrollIntoView({behavior: "smooth"})
  }
}

function deleteConfig() {
  store.commit("deleteServer", server.value.id)
  store.commit("setSidebarVisibility", false)
  deleteServerModal.value = false
  emit('deleteServerButton');
}

function checkIfAnyAdditionalArticle(){
  let dataName = ""
  for (let item of priceList.value) {
    for (const [key, value] of Object.entries(server.value.configuration)){
      dataName = item[0].replace(" ","_")
      if (item[1].type === 'Zusatz-Artikel' && dataName === key && server.value.type === "cloudServer" && value >0){
       return true
      }
    }
  }
  return false
}

</script>

<style lang="scss" scoped>

.editDeleteCreateServer {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 150px;
}

.mainCloudServer {
  height: 500px;
}

.flexBetween {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.fade-in {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-leave-active {
  transition: opacity 1s;
}

.fade-leave-to {
  opacity: 0;
}

.grey-bar {
  background-color: #4d4d4d;
  position: absolute;
  z-index: 1;
}

.kdz-height {
  display: flex;
  justify-content: flex-end;
  min-height: 250px;
  height: 500px;
  height: auto;
}
@media screen and(max-width: 995px) {
  .kdz-height {
    height: 500px;
  }
}
.kdz-config-img {
  position: absolute;
  width: 150px;
  z-index: 2;
}

.kdz-bg-grey {
  background-color: rgba(135, 135, 135, 0.11);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 10px;
}

.panel {
  //background: url("../../assets/panel.svg") no-repeat;
  position: absolute;
  width: 180px;
  //height: 45px;
  padding: 20px 20px;
  text-align: center;
  font-size: 14px;
}


.server {
  top: 35%;
  width: 180px;
  left: 40%;
  z-index: 4;

  /* &.kdz-small {
    position: relative;
    width: 50px;
    left: 10%;
    top: 20%;
  } */
}

.panel-cloud {
  top: 25%;
  left: 50px;
  padding-top: 20px;
}

.add-server {
  width: 300px;
  top: 25%;
  left: 10px;
}

.server-cloud {
  top: 0;
  left: 5%;
  width: 300px;
}

.kdz-server-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  display: block;
}

.editCloudServer {
  height: auto;
  margin-bottom: 10px;
  font-size: 13px;
  width: 200px;

  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #efefef;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    background: #fff;
  }
}

.cpu {
  top: 10%;
  right: 15%;
  opacity: 1;
}

.ram {
  bottom: 10%;
  left: 5%;
}

.ssd {
  top: 20%;
  left: 8%;
  width: 100px;
}

.os {
  bottom: 15%;
  right: 30%;
  z-index: 6;
  width: 100px;
}

.bar-horizontal-ssd {
  width: 150px;
  height: 10px;
  top: 40%;
  left: 15%;
}

.bar-vertical-ssd {
  width: 10px;
  height: 50px;
  top: 31%;
  left: 15%;
}

.bar-horizontal-cpu {
  width: 130px;
  height: 10px;
  top: 43%;
  right: 26%;
}

.bar-vertical-cpu {
  width: 10px;
  height: 60px;
  top: 33%;
  right: 26%;
}

.bar-vertical-ram {
  width: 10px;
  height: 30px;
  bottom: 24%;
  left: 15%;
}

.bar-horizontal-ram {
  width: 150px;
  height: 10px;
  bottom: 23%;
  left: 15%;
}

.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}

.firewall {
  z-index: 5;
  width: 50px;
  top: 38%;
  left: 37%;
}

.mainHeight {
  height: 500px;
}

@media (min-width: 1161px) {


  .server {
    top: 37%;
    left: 40%;
    z-index: 4;

    &.kdz-small {
      width: 50px;
      left: 10%;
    }
  }

  .panel-cloud {
    top: 30%;
    left: 45%;
  }

  .add-server {
    width: 200px;
    top: 25%;
    left: 60%;
  }

  .server-cloud {
    position: absolute;
    top: 13%;
    left: 18%;
    width: 370px;
  }

  .cpu {
    top: 10%;
    right: 28%;
  }

  .ram {
    bottom: 15%;
    left: 14%;
  }

  .ssd {
    top: 35%;
    left: 20%;
    width: 100px;
  }

  .hdd {
    top: 8%;
    left: 40%;
    width: 100px;
  }

  .os {
    bottom: 18%;
    right: 37.5%;
    width: 100px;
  }
  .office {
    bottom: 15%;
    right: 16%;
    width: 200px;
  }

  .firewall {
    width: 50px;
    top: 38%;
    left: 37%;
  }

  .bar-horizontal-ssd {
    width: 150px;
    height: 10px;
    top: 45%;
    left: 27%;
  }

  .bar-vertical-hdd {
    width: 10px;
    height: 60px;
    top: 28%;
    left: 47%;
  }

  .bar-horizontal-cpu {
    width: 130px;
    height: 10px;
    top: 43%;
    right: 36%;
  }

  .bar-vertical-cpu {
    width: 10px;
    height: 60px;
    top: 33%;
    right: 36%;
  }

  .bar-vertical-ram {
    width: 10px;
    height: 40px;
    bottom: 23%;
    left: 25%;
  }

  .bar-horizontal-ram {
    width: 160px;
    height: 10px;
    bottom: 30%;
    left: 25%;
  }
}

@media (max-width: 1200px) {

  .server-cloud {
    position: absolute;
    top: 17%;
    left: 16%;
    width: 370px;
  }
  .panel-cloud {
    top: 35%;
    left: 54%;
  }
  .kdz-config-img {
    position: absolute;
    width: 120px;
    z-index: 2;
  }
  .cpu {
    top: 12%;
    right: 30%;
  }

  .ram {
    bottom: 19%;
    left: 16%;
  }

  .ssd {
    top: 42%;
    left: 8%;
    width: 80px;
  }
  .hdd {
    top: 14%;
    left: 22%;
    width: 80px;
  }

  .os {
    bottom: 27%;
    right: 39%;
    z-index: 4;
    width: 80px;
  }

  .office {
    bottom: 24%;
    right: 15%;
    width: 160px;
  }
  .server {
    top: 37%;
    left: 35%;
    width: 144px;
  }
  .firewall {
    width: 40px;
    top: 38%;
    left: 33%;
  }

  .bar-horizontal-ssd {
    width: 150px;
    height: 10px;
    top: 50%;
    left: 20%;
  }

  .bar-vertical-hdd {
    width: 10px;
    height: 85px;
    top: 25%;
    left: 28%;
  }
  .bar-horizontal-hdd {
    width: 70px;
    height: 10px;
    top: 40%;
    left: 28%;
  }

  .bar-horizontal-cpu {
    width: 130px;
    height: 10px;
    top: 43%;
    right: 39%;
  }

  .bar-vertical-cpu {
    width: 10px;
    height: 60px;
    top: 33%;
    right: 39%;
  }

  .bar-vertical-ram {
    width: 10px;
    height: 40px;
    bottom: 27%;
    left: 25%;
  }

  .bar-horizontal-ram {
    width: 110px;
    height: 10px;
    bottom: 34%;
    left: 25%;
  }
}

@media (max-width: 930px) {

  .mainHeight {
    height: 300px;
  }
  .server-cloud {
    top: 20%;
    left: 16%;
    width: 300px;
  }
  .panel-cloud {
    top: 33%;
    left: 50%;
    transform: translateX(-50%);
  }
  .kdz-height {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 250px;
    height: 430px;
  }
  .editCloudServer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    width: 450px;
  }

  .mainCloudServer {
    height: 400px;
  }
  .flexBetween {
    align-items: center;
    margin-bottom: 0;
  }

  .cpu {
    top: 8%;
    right: 10%;
  }

  .ram {
    bottom: 30%;
    left: 13%;
  }

  .ssd {
    top: 30%;
    left: 8%;
    width: 80px;
  }
  .hdd {
    top: 5%;
    left: 38%;
    width: 80px;
  }

  .os {
    bottom: 39%;
    right: 30.5%;
    z-index: 4;
    width: 80px;
  }

  .office {
    bottom: 36%;
    right: -2%;
    width: 160px;
  }
  .server {
    top: 25%;
    left: 35%;
    width: 144px;
  }
  .firewall {
    width: 40px;
    top: 27%;
    left: 33%;
  }

  .bar-horizontal-ssd {
    width: 150px;
    height: 10px;
    top: 39%;
    left: 20%;
  }

  .bar-vertical-hdd {
    width: 10px;
    height: 40px;
    top: 22%;
    left: 46%;
  }
  .bar-horizontal-hdd {
    width: 0px;
    height: 0px;
  }

  .bar-horizontal-cpu {
    width: 130px;
    height: 10px;
    top: 38%;
    right: 22%;
  }

  .bar-vertical-cpu {
    width: 10px;
    height: 60px;
    top: 28%;
    right: 22%;
  }

  .bar-vertical-ram {
    width: 10px;
    height: 40px;
    bottom: 36%;
    left: 25%;
  }

  .bar-horizontal-ram {
    width: 110px;
    height: 10px;
    bottom: 43%;
    left: 25%;
  }
  .kdz-server-title {
    font-size: 16px;
  }
}


</style>
