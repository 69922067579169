<template>
  <a-spin :spinning="loading">
    <a-modal
        v-model:open="openModal"
        :title="'Artikel erstellen'"
        :closable="false"
        :maskClosable="false"
        cancelText="Abbrechen"
        @cancel="closeModal"
        @ok="handleSaveArticleSubmit"
        destroyOnClose
        :confirmLoading="loading"
        centered>
      <a-form
          ref="formRef"
          :model="form"
          :rules="checkInput"
          :loading="loading"
          layout="vertical"
          @finish="handleSaveArticleSubmit">
        <!-- WP Implementation -->
        <a-form-item ref="name" name="name">
          <template #label>
            <TooltipLabel :title="'Name des Artikels'" :tooltip="'Name des Artikels'"/>
          </template>
          <a-input v-model:value="form.name" :placeholder="'Name des Artikels'" />
        </a-form-item>
        <a-form-item ref="price" name="price">
          <template #label>
            <tooltip-label :title="'Artikelpreis'" :tooltip="'Artikelpreis'"/>
          </template>
          <a-input
              v-model:value="form.price"
              placeholder="Artikelpreis" />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-spin>
</template>

<script setup>

import api from '@/apis/apis'
import { ref, reactive, toRaw, defineEmits, watch } from 'vue'
import { notification } from 'ant-design-vue'
import TooltipLabel from "@/components/TooltipLabel.vue";


const enableArticleBool              = ref(true)
const openModal                     = ref(false)
const loading                       = ref(false)
const formRef                       = ref()
const onlyNumberRegex           = /^\d+([.,]\d{1,2})*$/ //only digits,comma and dot allowed up to two decimal digits
const noSpacesStartEndRegex = /^[^\s]+(\s+[^\s]+)*$/

const form = reactive({
  name: "",
  price: "",
});

//PROPS
const props = defineProps({
  createArticleModal: {
    type: Boolean,
    required: true
  },
  accountId: {
    type: String,
    required: true
  }
})

const checkInput = {
  name : [
    { required: true, message: 'Bitte geben Sie das Namen des Artikels an.'},
    { validator: validateCheckName }
  ],
  price: [
    { required: true, message: 'Bitte geben Sie den Preis des Artikels an.'},
    { validator: validateCheckPrice }
  ]
}

//WATCH
watch(() => props.createArticleModal, (newVal) => {
  if (newVal === true) {
    form.account = props.accountId
  }
  openModal.value = newVal
})


//EMITS
const emit = defineEmits(['reload-articles', 'close-modal'])

//FORMS
const initForm = () => {
  formRef.value = null
}

initForm()

async function  handleSaveArticleSubmit (e) {
  e.preventDefault()
  if (loading.value) return
  formRef.value.validate().then(async () => {
    const values = toRaw(form)
    var params = new FormData()
    loading.value = true
    // Need the reseller uuid for creating the articles data
    params.append('accountUuid', props.accountId)
    params.append('name', values.name)
    params.append('price', values.price)

    await api.saveAdditionalArticles(params).then(async response => {
        notification.success({
          message: 'Erfolg',
          description: 'Der Artikel wurde erfolgreich erstellt.',
          duration: 4
        })
        closeModal()
        loading.value = false
        emit('reload-articles')
    }).catch(error => {
      closeModal()
      loading.value = false
      notification.error({
        message: 'Fehler beim Erstellen des Artikels.',
        description:
            'Bei der Erstellung des Artikels ist ein Fehler aufgetreten, bitte versuchen Sie es erneut. (' + error.response.data + ')',
        duration: 0
      })
    })
  }).catch(error => {
    console.log(error)
    loading.value = false
  })
}

async function validateCheckName(rule, value){
  if (!noSpacesStartEndRegex.test(value) ) {
    const errorMessage = 'Der Name darf nicht mit einem Leerzeichen beginnen oder enden!'
    return Promise.reject(errorMessage)
  }else{
    return Promise.resolve()
  }
}


async function validateCheckPrice(rule, value){
  if(!onlyNumberRegex.test(value)){
    const errorMessage = 'Nur Zahlen,Punkt oder Komma und bis zu maximal zwei Dezimalstellen sind erlaubt!'
    return Promise.reject(errorMessage)
  }
  return Promise.resolve()
}

function closeModal(){
  form.name = undefined
  form.price = undefined
  enableArticleBool.value = false
  emit('close-modal')
}


</script>

<style scoped lang="scss">

</style>
