<template>
<!--   <div>{{ options }}</div>-->
  <div v-if="!options[0].includes('container') && !options[0].includes('GPUs')">
    <div v-if="(serverType === 'iaas' || serverType === 'cloudServer') && options[1].label !== 'Cyber Cloud Endpoint'">
      <div v-if="options[1].isCheckbox ? (options[1].label === 'Next Generation Firewall' ? false : true) : false" class="sideBarElementLine">
        <span>
          <span>{{ options[1].label }}</span>&nbsp;
          <a-tooltip id="hint" v-if="options[1].popover.content !== null">
            <template #title>{{ options[1].popover.content }}</template>
            <ExclamationCircleOutlined />
          </a-tooltip>
        </span>
        <div>
          <a-switch
            :id="options[1].label"
            v-model:checked="model"
            :name="'check-' + model"
            :unchecked-value="0"
            :checked="1"
            :disabled="isTemplate"
          >
          </a-switch>
        </div>
      </div>
      <!-- Input Number -->
      <div
        style="display: flex; justify-content: space-between; align-items: center; padding: 2px;"
        :label-for="options[1].label"
        class="align-items-center"
        v-else-if="options[1].isOS === false && options[1].isResource === false && options[1].type !== 'Zusatz-Artikel'"
      >
        <span style="text-align: left; width: 212px;">
          <span>{{ options[1].label }}
          </span>&nbsp;
          <a-tooltip id="hint" v-if="options[1].popover.content !== null">
            <template #title>{{ options[1].popover.content }}</template>
            <ExclamationCircleOutlined />
          </a-tooltip>
        </span>
        <a-input
          type="number"
          style="width: 60px;"
          v-model:value="model"
          :id="options[1].label"
          :min="0"
          :max="200000"
          :disabled="isTemplate && options[1].type !== 'Software per User'"
        ></a-input>
      </div>
      <div
        style="display: flex; justify-content: space-between; align-items: center; padding: 2px;"
        :label-for="options[1].label"
        class="align-items-center"
        v-else-if="options[1].isOS === true && options[1].isResource === false"
      ><span>{{ options[1].label }}</span>&nbsp;
    </div>
      <div
        :label-for="options[1].label"
        id="isResourceInputContainer"
        class="align-items-center sideBarElementLine"
        v-else-if="isResource ? (selectedServer.type === 'cloudServer' ? (options[1].label === 'Next Generation Firewall' ? false : true) : (options[1].label === 'Öffentliche IP' || options[1].label === 'Next Generation Firewall' ? false : true)) : false"
      >
        <span style="text-align: left; width: 70%;">
          <span style="word-wrap: break-word; width: 100%;"> {{ options[1].label }}
          </span>&nbsp;
          <a-tooltip id="hint" v-if="options[1].popover.content !== null">
            <template #title>{{ options[1].popover.content }}</template>
            <ExclamationCircleOutlined />
          </a-tooltip>
          <a-tooltip id="hint" v-else-if="store.getters.getTemplateRootDiskSize > 0 && options[0] === 'SSD'">
            <template #title>{{ 'Aufgrund der benötigten Root-Festplattengröße des ausgewählten Templates beträgt der Mindestwert für SSD ' + store.getters.getTemplateRootDiskSize + 'GB!' }}</template>
            <ExclamationCircleOutlined />
          </a-tooltip>
        </span>
        <a-input-number
          :class="['inputNumField', { inputFieldRequired: (setRequireField(options[0]) && submitButtonClicked) ? true : false }]"
          v-model:value="model"
          :id="options[1].label"
          :min="options[0] === 'SSD' ? checkDiskMinRootSize : 0"
          :max="checkMaxLength(options[0])"
          :disabled="isTemplate"
        ></a-input-number>
      </div>
    </div>
    <div v-if="serverType === 'wpCloud'">
      <div v-if="options[1].label.includes('CPU Kerne')" style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span>{{ options[1].label }}</span>&nbsp;
        </span>
        2
      </div>
      <div v-if="options[1].label === 'RAM in GB'" style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span>{{ options[1].label }}</span>&nbsp;
        </span>
        4
      </div>
      <div v-if="options[1].label === 'SSD Storage in GB'" style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span>{{ options[1].label }}</span>&nbsp;
        </span>
        100
      </div>
    </div>
  </div>
  <div v-else-if="options[0] === 'GPUs'" style=" display: flex; flex-direction: row; align-items: center; padding: 2px;">
    <div style="width: 100%; display: flex; flex-direction: column;">
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" >
          <span style="text-align: left; margin-left: 2px;">{{ 'GPU' }}</span>&nbsp;
          <a-switch v-model:checked="gpuEnabled" @change="resetGPUs()" style=" text-align: right; margin-right: 2px;"></a-switch>
      </div>
      <div v-if="gpuEnabled" style="width: 100%;">
        <a-select
          id="gpuSelect"
          v-model:value="gpu"
          style="width: 100%; padding: 2px;">
          <template v-for="item in options[1]" :key="item.value">
            <a-select-option  :value="item.name">
              <a-tooltip placement="right">
                <template #title>{{ item.popover.content }}</template>
                <span style="display: flex; align-items: center;">
                  {{item.label}}
                </span>
              </a-tooltip>
            </a-select-option>
          </template>
        </a-select>
      </div>
    </div>
  </div>
</template>

<script setup>

  //IMPORTS
  import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
  import { ref, onMounted, computed} from 'vue'
  import store from '@/store'

  //VARIABLES
  const gpuEnabled = ref(false)

  //LIFECYCLE HOOKS
  onMounted(() => {
    getGpuStatus()
  });

  //PROPS
  const props = defineProps({
    options: {
      type: Array,
      required: true
    },
    isResource: {
      type: Boolean,
      required: false
    },
    requireCpu: {
     type: Boolean,
      required: false
    },
    requireRam: {
      type: Boolean,
      required: false
    },
    requireStorage: {
      type: Boolean,
      required: false
    },
    submitButtonClicked: {
      type: Boolean,
      required: false
    },
    serverType: {
      type: String,
      required: true
    },
    isTemplate: {
      type: Boolean,
      required: false,
      default: false
    }
  })

  //COMPUTED PROPERTIES
  const selectedServer = computed(() => store.getters.selectedServer);
  const checkDiskMinRootSize = computed(() => {
    const rootDiskSize = store.getters.getTemplateRootDiskSize
    const ssd = selectedServer.value.configuration["SSD"]

    if (rootDiskSize > 0) {
      if (ssd < rootDiskSize) {
        updateConfig("SSD", rootDiskSize)
      }
      return parseInt(rootDiskSize)
    }
    return 0
  })
  const model = computed({
    get() {
      if (props.options[1].isCheckbox) {
        var modelValue = selectedServer.value.configuration[props.options[0]]
        if (modelValue === 1 ){
          modelValue = true
          return modelValue
        }
      }
      else {
        var modelValue = parseInt(selectedServer.value.configuration[props.options[0]])
      }
      return parseInt(modelValue)
    },

    set(value) {
      if (value >= 0) {

        //CHECK CYBER CLOUD AGENT AND CYBER CLOUD REMOTE BACKUP
        if(value === true && props.options[0] === "AcronisRBS"){
          activateCyberCloudAgent()
        }

        if(value === false && props.options[0] === "AcronisRBS"){
          deactivateCyberCloudAgent()
        }

        if(value === false && props.options[0] === "AcronisCSS"){
          deactivateCyberCloudRemoteBackup()
        }

        // CALCULATION FOR RESOURCES
        if (props.options[0] === "HDD" || props.options[0] === "SSD") {
          if ( value > 4096) {
            value = 4096
            updateConfig(props.options[0], parseInt(value))
            return
          }
        }

        if (props.options[0] === "MEMORY") {
          if ( value > 512) {
            value = 512
            updateConfig(props.options[0], parseInt(value))
            return
          }
        }

        if (props.options[0] === "vCPU") {
          if (value > 0) {
            if ( value > 32) {
              value = 32
            }
            let server = selectedServer.value
            if (((server.configuration.os).toLowerCase()).includes("windows") === true) {
              server.configuration[server.configuration.os] = server.configuration['vCPU']
            }
            updateConfig(props.options[0],  parseInt(value))
            return
          }
        }

        if (props.options[0] === "HDD" || props.options[0] === "SSD" || props.options[0] === "MEMORY" || props.options[0] === 'vCPU'){
          if (value == "" || value == null){
            updateConfig(props.options[0], 0)
          } else {
            updateConfig(props.options[0],  parseInt(value))
          }
          return
        }
        // CALCULATION FOR LICENSES
        if (props.options[0] === "MS_Office_2021_1_User") {
          if ( value > 20) {
            value = 20
            updateConfig(props.options[0], parseInt(value))
          }
        }

        if (props.options[0] === "TSPlus_1_User") {
          if ( value > 30) {
            value = 30
            updateConfig(props.options[0], parseInt(value))
          }
        }

        if (value > 999) {
          value = 999
          updateConfig(props.options[0], parseInt(value))
        }
        if (value === true) {
          value = 1
          updateConfig(props.options[0], value)
        } else {
          updateConfig(props.options[0],  parseInt(value))
        }
        }

        if (props.options[0].includes('GPU-')) {
          updateGpusInConfig(props.options[0], parseInt(value))
        }
        if (props.options[0] === 'MSSQL_Standard_(Core_License)' && selectedServer.value.configuration['MSSQL_Standard_(User_License)'] > 0) {
          updateConfig(props.options[0], parseInt(value))
          updateConfig('MSSQL_Standard_(User_License)', 0)
        } else if (props.options[0] === 'MSSQL_Standard_(User_License)' && selectedServer.value.configuration['MSSQL_Standard_(Core_License)'] > 0) {
          updateConfig(props.options[0], parseInt(value))
          updateConfig('MSSQL_Standard_(Core_License)', 0)
        }
        if (props.options[0] === 'libre' && selectedServer.value.configuration['MS_Office_2021_1_User'] > 0) {
          updateConfig(props.options[0], parseInt(value))
          updateConfig('MS_Office_2021_1_User', 0)
        } else if (props.options[0] === 'MS_Office_2021_1_User' && selectedServer.value.configuration['libre'] > 0) {
          updateConfig(props.options[0], parseInt(value))
          updateConfig('libre', 0)
        }
     }
  })

  const gpu = computed({
    get() { 
      let returnValue = 'GPU wählen'
      for (const [key, value] of Object.entries(selectedServer.value.configuration)) { 
        if (key.includes('GPU-') && value === 1) { 
          returnValue = key
          gpuEnabled.value = true
        } 
      } 
      return returnValue
    }, 

    set(value) { 
      if (value !== '') { 
        updateGpusInConfig(value, 1)
      } 
    }
  })

  //FUNCTIONS
  function setSelectedConfiguration (configuration) {
    store.commit('setSelectedConfiguration', configuration)
  }

  function updateConfig(attribute, value) {
      let server = selectedServer.value;
      server.configuration[attribute] = value;
      setSelectedConfiguration(server.configuration)
  }

  function activateCyberCloudAgent() {
    let server = selectedServer.value
    server.configuration["AcronisCSS"] = 1
  }

  function deactivateCyberCloudAgent() {
    let server = selectedServer.value
    server.configuration["AcronisCSS"] = 0
  }

  function deactivateCyberCloudRemoteBackup() {
    let server = selectedServer.value
    server.configuration["AcronisRBS"] = 0
  }

  function getGpuStatus(){
    for (const [key, value] of Object.entries(selectedServer.value.configuration)) {
      if (key.includes('GPU-') && value === 1) {
        gpuEnabled.value = true
        return key
      }
    }
  }

  function setRequireField(input) {
      if (input === 'vCPU') {
        return props.requireCpu
      } else if (input === 'MEMORY') {
        return props.requireRam
      } else if (input === 'SSD' || input === 'HDD') {
        return props.requireStorage
      }
    }

  function checkMaxLength(usageType) {
      if (usageType === "HDD" || usageType === "SSD" || usageType === "MEMORY") {
        let maxLength = 9999
        return maxLength
      } else {
        let maxLength = 999
        return maxLength
      }
    }

  function updateGpusInConfig(attribute, value) {
    let server = selectedServer.value
    for (const [key, _] of Object.entries(server.configuration)) {
      if (key === attribute) {
          continue
      } else if (key.includes('GPU-')) {
          server.configuration[key] = 0
      }
    }
    server.configuration[attribute] = value
  }

  function resetGPUs () {
      if (!gpuEnabled.value) {
        let server = selectedServer.value
        for (const [key, _] of Object.entries(server.configuration)) {
          if (key.includes('GPU-')) {
            server.configuration[key] = 0
          }
        }
      }
    }

</script>

<style lang="scss" scoped>
.inputNumField {
  width: 60px;
  margin-top: 5px;
}
.inputFieldRequired {
  width: 60px;
  margin-top: 5px;
  border-color: red;
  border-width: 2px;
}
.sideBarElementLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 2px;
}
.collapseHeader {
  display: flex;
  text-align: left;
}
.disabled {
  opacity: 0.5;
}
</style>
