<template>
  <a-spin :spinning="loading">
    <h1>Einstellungen</h1>
    <a-tabs v-model:activeKey="activeKey" type="card" style="width: 1050px; margin: auto;" @change="changeTabs">
      <a-tab-pane :key="'1'" tab="Logo">
        <div id="chooseLogo">
          <h2>Logo für Angebotserstellung</h2>
          <a-upload
            v-model:file-list="fileList"
            name="avatar"
            list-type="picture-card"
            class="avatar-uploader"
            :max-count=1
            :show-upload-list="true"
            accept=".png, .jpg"
            :custom-request="customRequest"
            :before-upload="beforeUpload"
            @change="handleChange"
            @preview="handlePreview"
            @remove="handleRemove"
            >
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
            <div v-else>
              <div class="ant-upload-text">Bilddatei wählen</div>
            </div>
          </a-upload>
          <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
          <a-button v-if="isButtonVisible" id="uploadButton" type="primary" @click="sendImage()"> <UploadOutlined/> Upload</a-button>
          <br>
          <h4>Hinweis: Gültige Dateiformate sind png und jpeg.</h4>
          <h4>Die maximale Breite darf 600 px und die maximale Höhe 200 px betragen.</h4>
        </div>
      </a-tab-pane>
      <a-tab-pane :key="'2'" tab="Preisliste">
        <div class="search-container">
          <a-tooltip placement="left">
            <template #title>
              {{ 'Alle Änderungen speichern' }}
            </template>
            <a-button class="priceListButtonContainer" :disabled="!firstInputVk" @click="setSellingPrice(record)" type="primary"><SaveOutlined /></a-button>
          </a-tooltip>
          <a-tooltip placement="right">
            <template #title>
              {{ 'Alle geänderten Verkaufspreise zurücksetzen' }}
            </template>
            <a-button class="priceListButtonContainer" @click="showModal" type="primary"><ReloadOutlined /></a-button>
          </a-tooltip>
          <a-modal
            v-model:open="isModalVisible"
            title="Verkaufspreise zurücksetzen"
            @ok="handleModalOk"
            @cancel="handleModalCancel"
            >
            <p>Möchten Sie die Verkaufspreise auf die Standardwerte zurücksetzen?</p>
          </a-modal>
          <a-input-search class="searchFieldInputUsageName" v-model:value="searchInput" placeholder="Suchtext..." @change="handleSearch()" size="default">
            <template #enterButton>
              <a-button class="resetButton" type="primary" @click="handleSearchReset()">X</a-button>
            </template>
          </a-input-search>
        </div>
        <div :key="updateList" class="priceTableContainer">
          <a-table class="priceTable" size="small" :showHeader="true" :columns="columns" :data-source="currentPriceList" :showSorterTooltip="false">
            <template v-slot:bodyCell="{record, column}">
              <template v-if="column.dataIndex === 'name'">
                <span>{{ record.usage_name === undefined ? record.created : record.usage_name }}</span>
              </template>
              <template v-if="column.dataIndex === 'price'">
                <span>{{ record.default_price}}</span>
              </template>
              <template v-if="column.dataIndex === 'rabatt%'">
                <span>{{ record.default_discount}}</span>
              </template>
              <template v-if="column.dataIndex === 'rabatt€'">
                <span>{{ record.default_price_discounted}}</span>
              </template>
              <template v-if="column.dataIndex === 'verkaufspreis'">
                <a-input-number :class="{ 'input-active': record.isActive }" style="width: 150px;" :min="0" :controls="false" decimalSeparator="," :precision="4" v-model:value="record.vk_price" :id="record.recordid.toString()" @change="handleEnter(record)"></a-input-number>
              </template>
            </template>
          </a-table>
          <div class="priceListInfoText">
            <h4>*Alle oben genannten Preise verstehen sich als Netto</h4>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane :key="'3'" tab="Account">
        <div class="alignRight" style="width: 100%;">
          <a-button type="primary" @click="openModal()">Bearbeiten</a-button>
        </div>
        <a-list
          size="small"
          :loading="accountLoading">
          <a-list-item v-for="(item, index) in accountInfo" :key="index" class="alignLeft" >
            <div class="alignLeft" style="flex-direction: column;">
              <strong class="alignLeft">{{ translatedForm[index]}}</strong>
              <div class="alignLeft">{{ item ? item : "-" }}</div>
            </div>
            <br>
          </a-list-item>
        </a-list>
        <EditAccount
        :accountResource="accountResource"
        :editAccountModal="open"
        @close-modal="closeModal"
        @reload-accounts="getAccountData"/>
      </a-tab-pane>
      <a-tab-pane :key="'4'" tab="Zusatzartikel">
        <div class="customerHeaderContainer">
          <div class="searchFieldCustomers">
            <a-tooltip placement="top">
              <template #title>
                {{ 'Artikel hinzufügen' }}
              </template>
              <a-button id="createAccountBtn" class="createAccountButton" @click="showCreatedArticle"><PlusOutlined /></a-button>
            </a-tooltip>
          </div>
        </div>
        <a-table class="priceTable" size="small" :showHeader="true" :columns="columnsAdditionalArticles" :data-source="currentAdditionalPriceList" :showSorterTooltip="false">
          <template v-slot:bodyCell="{record, column}">
            <template v-if="column.dataIndex === 'name'">
              <span>{{ record.name }}</span>
            </template>
            <template v-if="column.dataIndex === 'price'">
              <span>{{ parseFloat(record.price).toFixed(2).toString().replace('.', ',')}}</span>
            </template>
            <template v-if="column.dataIndex === 'state'">
              <span v-if="record.enabled === true">{{'Aktiviert'}}</span>
              <span v-else>{{'Deaktiviert'}}</span>
            </template>
            <template v-if="column.dataIndex === 'edit'">
              <div>
                <a-tooltip placement="left">
                  <template #title>
                    {{ 'Artikel bearbeiten' }}
                  </template>
                  <a-button @click="handleEditArticle(record.id, record.name,record.enabled,record.price, $event)" class="tableButton editButton"><EditOutlined /></a-button>
                </a-tooltip>
                <a-tooltip placement="right">
                  <template #title>
                    {{ 'Artikel löschen' }}
                  </template>
                  <a-button @click="handleDeleteArticle($event, record.id,record.name)" type="primary" class="tableButton"><DeleteOutlined /></a-button>
                </a-tooltip>
              </div>
            </template>
          </template>
        </a-table>
        <AdditionalArticle :createArticleModal="showCreateArticles"
                           :accountId = "accountUuid"
                           @close-modal="closeModal"
                           @reload-articles="getAdditionalArticlesList" />
        <DeleteAdditionalArticles
            :deletionArticleModal="deleteModalArticleBoolean"
            :article-name ="resourceArticleName"
            :article-uuid ="articleuuid"
            @close-modal="closeModal"
            @reload-articles="getAdditionalArticlesList"
        ></DeleteAdditionalArticles>
        <EditAdditionalArticles :account-id= "accountUuid"
                                :article-id="articleuuid"
                                @close-modal="closeModal"
                                @reload-articles="getAdditionalArticlesList"
                                :editarticle="showEditArticle"
                                :article-resource="articleObj"

        />
      </a-tab-pane>
    </a-tabs>
  </a-spin>
</template>

<script setup>

  //IMPORTS
  import {ref, onMounted, reactive} from 'vue'
  import { onBeforeUnmount } from 'vue'
  import { message, notification} from 'ant-design-vue'
  import {
    UploadOutlined,
    SaveOutlined,
    ReloadOutlined,
    PlusOutlined,
    DeleteOutlined, EditOutlined
  } from '@ant-design/icons-vue'
  import api from '@/apis/apis'
  import EditAccount from '../components/forms/EditAccount.vue'
  import AdditionalArticle from "@/components/forms/SaveAdditionalArticle.vue"
  import DeleteAdditionalArticles from "@/components/forms/DeleteAdditionalArticles.vue";
  import EditAdditionalArticles from "@/components/forms/EditAdditionalArticles.vue";

  //VARIABLES
  const fileList = ref([])
  const imageUrl = ref('')
  const previewVisible = ref(false)
  const previewImage = ref('')
  const previewTitle = ref('')
  const activeKey  = ref('1')
  const currentPriceList = ref([])
  const currentAdditionalPriceList = ref([])
  const deleteModalArticleBoolean =  ref(false)
  const resourceArticleName = ref('')
  const articleuuid = ref('')
  const showCreateArticles = ref(false)
  const showEditArticle = ref(false)
  const isButtonVisible = ref(false)
  const isModalVisible = ref(false)
  var firstInputVk = false
  const accountLoading = ref(false)
  const open = ref(false);
  const accountInfo = {
    accountName: undefined,
    companyname: undefined,
    customerID: undefined,
    contractNumber: undefined,
    street: undefined,
    postalcode: undefined,
    city: undefined,
    country: undefined
  }
  const accountResource = {
    accountname: undefined,
    accountuuid: undefined,
    domainuuid: undefined
  }
  const translatedForm = {
    accountName: 'Name des Benutzerkontos',
    companyname: 'Firmenname',
    customerID: 'Kundennummer',
    contractNumber: 'Vertragsnummer',
    street: 'Straße + Hausnummer',
    postalcode: 'PLZ',
    city: 'Stadt',
    country: 'Land',
  }
  var filteredList = []
  var tableBackup = []
  var loading = false
  var responseString
  var responseBase64
  var base64data
  var accountUuid
  var searchInput = ''
  var updateList = 0

  const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '300px',
    sorter: (a, b) => a.usage_name.localeCompare(b.usage_name),
  },
  {
    title: 'Preis in €',
    dataIndex: 'price',
    key: 'prices',
    width: '200px'
  },
  {
    title: 'Rabatt in %',
    dataIndex: 'rabatt%',
    key: 'rabatt&'
  },
  {
    title: 'EK Rabattiert',
    dataIndex: 'rabatt€',
    key: 'rabatt€'
  },
  {
    title: 'VK in €',
    dataIndex: 'verkaufspreis',
    key: 'verkaufspreis',
    width: '200px'
  }
];

  const columnsAdditionalArticles = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Preis in €',
      dataIndex: 'price',
      key: 'price',
      width: '200px'
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      width: '120px'
    },
    {
      key: 'edit',
      dataIndex: 'edit',
      align: 'right'
    }
  ];

  const articleObj = reactive({
    name: '',
    price: '',
    articleStatus: true
  })

  //LIFECYCLE HOOKS
  onBeforeUnmount(() => {
    if (imageUrl.value) {
      URL.revokeObjectURL(imageUrl.value) // Speicher freigeben
    }
  })

  onMounted(async () => {
    accountUuid = getCookie('account')
    await getCustomerLogo()

    if(responseBase64.length > 0)
    {
      fileList.value.pop()
      fileList.value.push({
        name: 'image',
        status: 'done',
        thumbUrl: responseBase64,
        url: responseBase64
      })
    }
  })

  //FUNCTIONS
  const openModal = () => {
    open.value = true;
  };
  const closeModal = () => {
    open.value = false;
    showCreateArticles.value = false
    deleteModalArticleBoolean.value = false
    showEditArticle.value = false
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  const handleCancel = () => {
    previewVisible.value = false
    previewTitle.value = ''
  }

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    previewImage.value = file.url || file.preview
    previewVisible.value = true
    previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
  }

  const customRequest = ({ file, onSuccess }) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      base64data = reader.result // Setze den Base64-String
      onSuccess(null, file) // Erfolgreicher Upload
    }

    reader.readAsDataURL(file)
  }

  const handleChange = ({ file }) => {
    if (file.status === 'error') {
      isButtonVisible.value = false
      message.error(`${file.name} konnte nicht hochgeladen werden.`)
    }

    if (file.status === 'done') {
      isButtonVisible.value = true
    }

    fileList.value = fileList.value.filter(item => item.uid !== file.uid).concat(file)
  }

  const handleRemove = async (file) => {
    fileList.value = fileList.value.filter(item => item.uid !== file.uid);
    isButtonVisible.value = false
    previewVisible.value = false
    previewTitle.value = ''
    await deleteCustomerLogo()
  }

  const beforeUpload = file => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      const reader = new FileReader()

      reader.onload = (e) => {
        img.src = e.target.result

        img.onload = () => {
          const { width, height } = img
          if (width > 600 || height > 200) {
            message.error('Das Bild muss kleiner als 600px Breite und kleiner als 200px Höhe sein.')
            reject(new Error('Bildgröße überschreitet das Limit.'))
          } else {
            resolve(true)
          }
        }

        img.onerror = () => {
          message.error('Fehler beim Laden des Bildes.')
          reject(new Error('Fehler beim Laden des Bildes.'))
        }
      }
      reader.readAsDataURL(file)
    })
  }

  async function sendImage(){
    accountUuid = getCookie('account')
     await setCustomerLogo()
     if(responseString.status == 200 && responseString.data == "true"){
        message.success("Die Datei wurde erfolgreich gespeichert.")
     }
  }

  function getCookie(cname) {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  async function changeTabs (e) {
    // is Pricelist Tab selected, load the list
    if (e == 2) { await getPriceList() }
    if (e == 3) { await getAccountData() }
    if (e == 4) {
      await getAdditionalArticlesList()
    }
  }

  async function setCustomerLogo() {
    let params = new FormData()
    params.append("image", base64data)
    params.append("account", accountUuid)
    await api.setCustomerLogo(params).then(response =>{
      responseString = response
    }).catch(error => {
      console.log("Error ", error)
    })
  }

  async function getCustomerLogo() {
    let params = new FormData()
    params.append("account", accountUuid)
    await api.getCustomerLogo(params).then(response =>{
      responseBase64 = response.data
    }).catch(error => {
      console.log("Error ", error)
    })
  }

  async function deleteCustomerLogo() {
    let params = new FormData()
    params.append("account", accountUuid)
    await api.deleteCustomerLogo(params).then(response =>{
      message.success("Die Datei wurde gelöscht.")
    }).catch(error => {
      console.log("Error ", error)
    })
  }

  async function getAccountData(){
    accountLoading.value = true
    await api.getResellerAccount(accountUuid).then(response => {
      accountResource.accountname = response.data.Name
      accountResource.domainuuid = response.data.DomainUuid
      accountResource.accountuuid = accountUuid
      accountInfo.accountName = response.data.Name
      accountInfo.companyname = response.data.CustomerName
      accountInfo.customerID = response.data.CustomerNumber
      accountInfo.contractNumber = response.data.ContractNumber
      accountInfo.street = response.data.Address
      accountInfo.postalcode = response.data.Plz
      accountInfo.city = response.data.City
      accountInfo.country = response.data.Country
      accountLoading.value = false
    }).catch(error => {
      console.log(error)
      accountLoading.value = false
    })
  }

async function getPriceList () {
  loading = true
  await api.listResellerPrices().then(response =>{
    searchInput = ''
    currentPriceList.value = response.data
    tableBackup = response.data
    loading = false
  }).catch(error => {
    loading = false
    console.log("error account",error)
  })
}

function handleEnter(record) {
  record.isActive = true
  var input = document.getElementById(record.recordid)
  if(input.value == "0.0000" || input.value == "" || input.value == undefined || parseInt(input.value) < 0){
    notification.error({
      message: 'Fehler!',
      description: 'Bitte gegen Sie einen Wert, größer als 0 €, ein',
      duration: 4
    });
    input.value = record.vk_price
    record.isActive = false
    return
   }

  let updatedCurrentPriceList = currentPriceList.value.map(element => {
    if(element.recordid === record.recordid) {
      return {... element, vk_price: input.value.toString()};
    }
    return element
  });

  currentPriceList.value = updatedCurrentPriceList
  firstInputVk = true
}

async function setSellingPrice () {
   loading = true
   currentPriceList.value.forEach(record => {
    record.isActive = false
   });

   let params = new FormData()
   params.append("pricelist", JSON.stringify(currentPriceList.value))
   await api.setVkPrice(params).then(response =>{
     loading = false
     notification.success({
        message: 'Erfolg!',
        description: 'Verkaufspreis wurde gespeichert',
        duration: 1,
     })
    }).catch(error => {
      loading = false
      console.log("Error ", error)
    })
}

const showModal = () => {
  isModalVisible.value = true;
 };

const handleModalOk = async () => {
  await resetSellingPrice();
  isModalVisible.value = false;
};

const handleModalCancel = () => {
  isModalVisible.value = false;
};

async function resetSellingPrice () {
  loading = true
  var message

  await api.resetVkPrices().then(response =>{
  loading = false
  message = response.data
  }).catch(error => {
      loading = false
      console.log("Error ", error)
  })

  if(message === "OK"){
    await getPriceList()
    updateList += 1
  }
}

function handleSearch () {
  filteredList = tableBackup.filter( x => x.usage_name.toString().toLowerCase().includes(searchInput.toString().toLowerCase()))
  currentPriceList.value = filteredList
  if (searchInput === '') {
    currentPriceList.value = tableBackup
  }
}

function handleSearchReset() {
  searchInput = ''
  currentPriceList.value = tableBackup
}

async function getAdditionalArticlesList() {
  loading = true
  await api.getAdditionalArticles(accountUuid).then(response =>{
    currentAdditionalPriceList.value = response.data
    loading = false
  }).catch(error => {
    loading = false
    console.log("error account",error)
  })
}

const showCreatedArticle = () => {
  showCreateArticles.value = true
}

async function handleDeleteArticle(e,id,name){
  e.preventDefault()
  deleteModalArticleBoolean.value = true
  resourceArticleName.value = name
  articleuuid.value = id
}

  async function handleEditArticle(id,name,state,price,e){
    e.preventDefault()
    articleObj.name = name
    articleObj.price = price
    articleObj.articleStatus = state
    articleuuid.value = id
    showEditArticle.value = true
  }

</script>

<style scoped>

.search-container {
  display: flex;
  align-items: stretch;
}

.priceListButtonContainer{

  display: flex;
  font-size: 25px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  height: 40px;
  width: 55px;
  bottom: 6px;
}
.searchFieldInputUsageName{
  margin-left: 560px;
  width: 360px;
}
.input-active {
  border: 2px solid rgba(45, 168, 7, 0.5);
}

.avatar-uploader > .ant-upload {
  width: 256px;
  height: 256px;
}
.ant-upload-select-picture-card i {
  font-size: 64px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 16px;
  color: #666;
}

#uploadButton {
  margin-right: 11%;
}

.priceListInfoText{
  position: relative;
  bottom: 60px;
  right: 350px;
}
.alignLeft {
  display: flex;
  justify-content: left;
}
.alignRight {
  display: flex;
  justify-content: right;
}

</style>
