<template>
  <a-spin :spinning="loading" size="large" tip="Der Angebotskalkulator wird eingerichtet...">
    <div v-if="!showCalculator" style="height: 500px; margin-top: 150px;"></div>
    <div id="cloud-konfigurator" class="cloudConfig" v-if="showCalculator">
      <div class="cloudSelect" >
        <div class="cloudSelectWidth"
          v-for="(cloud, index) in clouds"
          :key="index"
        >
          <CloudSelectItem
            class="selectItemMaxWidth"
            :title="cloud.title"
            :src="cloud.src"
            :linkName="cloud.link"
            :myID="index"
          />
        </div>
        <div style="display: flex; flex-direction: column;">
        </div>
      </div>
      <div class="kdz-main-field" style="display: flex;">
        <Main v-if="$route.path=== '/prices'"></Main>
        <router-view class="cloudServerMain"/>
        <div class="sidebar-costOverview">
          <Sidebar />
          <CostOverview />
        </div>
      </div>
  </div>
</a-spin>
</template>

<script setup>

//IMPORTS
import api from '@/apis/apis'
import CostOverview from "@/components/CostOverview.vue";
import CloudSelectItem from "@/components/CloudSelectItem";
import Sidebar from "@/components/Sidebar.vue";
import Main from '@/views/Main.vue'
import { toRaw, ref, computed, onMounted} from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'

//VARIABLES
const accountid                   = ref(getCookie('account'))
const showCalculator              = ref(false)
const loading                     = ref(false)

//COMPUTED PROPERTIES
const bill                  = computed(() => store.getters.bill)
const isSidebarOpen         = computed(() => store.getters.isSidebarOpen)
const getConfigImportId     = computed(() => store.gettes.getConfigImportId)
const originalDiscountType  = computed(() => store.getters.originalDiscountType)

const clouds = computed(() => {
    return [
      {
        title: "Container Deployments",
        link: "container",
        src: "container.png",
      }, 
      {
        title: "Desktop aus der Cloud/\n Cloud-Server",
        link: "cloudServer",
        src: "cloudserver.svg",
      },
      {
        title: "Infrastructure as a Service\n (IaaS)",
        link: "iaas",
        src: "IaaS.svg",
      },
      {
        title: "Online Office & Collaboration\n (DSGVO konform)",
        link: "collaboration",
        src: "wp_cloud.png",
      },
      {
        title: "Cyber Cloud Endpoint\n (Backup & Security)",
        link: "cybercloudendpoint",
        src: "backup.png",
      }, 
    ]
  })

//LIFECYCLE HOOKS
const fetchData = async () => {
  await displayPrices()
}

onMounted(() => {
  fetchData()
  store.commit("setSidebarVisibility", false)
  if (!store.getters.getConfigImportId) {
      store.commit("clearServer")
  }
})

onBeforeRouteLeave( async (to, from) => {
  var pathList = ['/cloudServer', '/iaas', '/collaboration', '/container', '/prices']
    if (store.getters.isWorkingOnOffer) {
      if (!pathList.includes(to.path)) {
      const confirm = window.confirm('Wollen Sie die Konfiguration wirklich verlassen? Nicht gespeicherte Daten gehen verloren!')
      if (!confirm) {
          store.commit('setCurrentMenuTab', ['2'])
          return false
        }
      }
    }
    if (!pathList.includes(to.path)) {
      if (isSidebarOpen) {
        store.commit('setSidebarVisibility', false)
      }
      store.commit('clearServer') 
    }

    store.commit("setWorkingOnOffer", false)

    let originalPartnerStatus;
    let selectedDiscount;

    // Hole den ursprünglichen Discount-Typ
    originalPartnerStatus = originalDiscountType.value;

    // Setze den Discount-Typ im Store
    store.commit("setDiscountType", originalPartnerStatus);

    switch (originalPartnerStatus) {
      case 'Holz':
        selectedDiscount = 20;
        break;
      case 'Bronze':
        selectedDiscount = 30;
        break;
      case 'Silber':
        selectedDiscount = 35;
        break;
      case 'Gold':
        selectedDiscount = 40;
        break;
      case 'Platin':
        selectedDiscount = 47;
        break;
      default:
        selectedDiscount = 0;
      }

      store.commit("setPartnerDiscount", selectedDiscount)
})


  //FUNCTIONS
  function getCookie(cname) {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return "";
  }

  // we have to set Popover messages and images to display manually to the map
  async function setManualParamsToPriceMap (hashMap) {
      for(let key of hashMap.keys()) {  
        if (key.includes('NextGenerationFirewall')) {
          // add popover to NGF
          hashMap.get(key).popover = { title: 'Hinweis', content: 'Die Next-Generation-Firewall ist ein mehrstufiges Sicherheitssystem zum Schutz vor modernen Cyberattacken' }
        } else if (key.includes('IP_ADDRESS')) {
          // add popover to IP Address
          hashMap.get(key).popover = { title: 'Hinweis', content: 'Die erste IP-Adresse ist kostenlos.' }
        } else if (key.includes('MS_Exchange')) {
          // add popover and IMG to MS_Exchange licenses
          hashMap.get(key).image = 'exchange-new.svg'
        } else if (key.includes('MSSQL') && key.includes('Core_License')) {
          // add popover and IMG to MSSQL Core licenses
          hashMap.get(key).image = 'microsoft-sql-server.png'
        } else if (key.includes('windows') || key.includes('Windows')) {
          // add popover and IMG Windows licenses
          hashMap.get(key).image = 'windows_icon.png'
        } else if (key.toLowerCase().includes('andere')) {
          hashMap.get(key).image = 'mitteldeutsche_logo_circle.svg'
        } else if (key.toLowerCase().includes('debian')) {
          hashMap.get(key).image = 'debian.svg'
        } else if (key.toLowerCase().includes('ubuntu')) {
          hashMap.get(key).image = 'ubuntu.png'
        }
      }
      hashMap.set('Linux', { data: { discount: 0 }, label: 'Linux', hoverLabel: 'Linux', price: 0, discount: "0.00", priceWithoutDiscount: 0, order: 0, popover: { title: null, content: null }, image: 'linus.svg', isLicense: true, isOS: true, isResource: false  })
      hashMap.set('libre', { data: { discount: 0 }, label: 'LibreOffice Lizenz', hoverLabel: 'LibreOffice', price: 0, discount: "0.00", priceWithoutDiscount: 0, order: 0, popover: { title: null, content: null }, isLicense: true, isOS: false, isResource: false  })
      return hashMap
  }

  async function displayPrices() {
    loading.value = true
    let myMap = new Map()
    await api.getCalculatorAccountPrices(accountid.value).then(response =>{
      myMap = new Map(Object.entries(response.data))
    }).catch(error => {
      console.log("error account",error)
    })
    // add Popover data and Text to each Resource that needs a popover manually!
      var rawMap = toRaw(myMap)
      store.commit('setPriceList', await setManualParamsToPriceMap(rawMap))
      await api.getAccountPrices(accountid.value).then(async response => {
        if (response.status === 200 ) {
          showCalculator.value = true
          loading.value = false
        }
        }).catch(error => {
            console.log(error)
        })
      await api.listResellerPrices().then(response =>{
          store.commit('setVkPriceList', response.data)
        }).catch(error => {
          console.log("error account",error)
        })
  }

</script>

<style lang="scss" scoped>
.cloudConfig * {
  box-sizing: border-box;
}
.selectItemMaxWidth {
  max-width: 250px;
}
.cloudSelect {
  max-width: 1220px;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.cloudConfig {
  width: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}
.kdz-main-field {
  //border: 1px solid blue;
  font-size: 16px;
  max-width: 1220px;
  width: 100%;
  margin-bottom: 10px;
}
.centering {
    display: none;
    justify-content: center;
    width: 100%; 
    align-items: center;
  }
.priceTable {
  height: 60%; 
  width: 900px; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  margin-top: 40px;
}
.searchField {
  display: flex;
  margin-bottom: 0.5%;
}
.resetButton {
  font-weight: bold; 
  width: 100%;
}
.cloudServerMain {
  border: none;
  position: relative;
  margin-right: 16px;
  width: 100%;
}


.sidebar-costOverview {
  //background: #fff;
  //box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  //border-radius: 5px;
  padding: 0px;
  border: none;
  position: relative;
}
.cloudSelectWidth {
  white-space: pre-wrap;
}

@media (max-width: 1200px) {
  .cloudServerMain {
    width: 100%;
  }

  .kdz-main-field {
     padding: 10px;
  }
  .cloudSelect {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 10px;
  }

}
@media (max-width: 1100px) {
  .cloudSelect {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 10px;
  }
}
@media (max-width: 930px) {
  .kdz-main-field {
    padding: 0 10px;
  }
  .cloudSelect {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px;
  }
  .cloudServerMain {
    width: 100%;
    padding: 0;
    //margin: 0;
  }
}

@media (max-width: 790px) {
  .kdz-main-field {
    padding: 0;
  }
  .cloudServerMain {
    margin: 0;
  }
  .cloudSelect {
    grid-template-columns: 1fr 1fr;
    padding: 10px 0;
    max-width: 450px;
  }

  .kdz-main-field {
  //border: 1px solid blue;
 // font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  }
  .sidebar-costOverview {
    display: flex; 
    justify-content: center;  
    //padding: 12px 12px 12px 12px;
    width: 100%;
    margin-top: 16px;
  }
}
@media (max-width: 490px) {
  .cloudSelect {
    grid-template-columns: 1fr 1fr;
    padding: 10px;
  }
  .cloudServerMain {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .kdz-main-field {
  //border: 1px solid blue;
 // font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  }

}
</style>