<template>
  <div class="centering">
    <div>
      <div class="selectButton">
        <a-select ref="select" v-model:value="year" style="width: 30%" @change="getInvoiceMonths(year)">
        <a-select-option :value="new Date().getFullYear()">{{ new Date().getFullYear() }}</a-select-option>
        <a-select-option :value="new Date().getFullYear()-1">{{new Date().getFullYear()-1}}</a-select-option>
        </a-select>
        <span v-if="invoiceDataTable.length === 0" >Keine Daten für diesen Zeitraum!</span>
      </div>
      <a-table v-if="invoiceDataTable.length !== 0" :columns="invoiceCols" :dataSource="invoiceDataTable" :loading="invoiceLoading" bordered :pagination="false">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'download'">
            <a-popover placement="left" :title="'Rechnung ' + record.month + ' ' + year">
              <span style="font-size: 25px;">
                <a-button style="height: 40px; font-size: 18px;" @click = "invoiceData(monthsEnglish.get(record.month))"><FilePdfOutlined /></a-button>
              </span>
              <template #content>
                <span>Rechnung als PDF Version herunterladen</span>
              </template>
            </a-popover>
          </template>
          <template v-if="column.key === 'downloadCsv'">
            <a-popover placement="right" :title="'Rechnung ' + record.month + ' ' + year">
              <span style="font-size: 25px;">
                <a-button style="height: 40px; font-size: 18px;" @click = "invoiceDataCsv(monthsEnglish.get(record.month))"><FileTextOutlined /></a-button>
              </span>
              <template #content>
                <span>Rechnung als CSV Version herunterladen</span>
              </template>
            </a-popover>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>

  //IMPORTS
  import api from '@/apis/apis'
  import { ref, onMounted } from 'vue'
  import { FileTextOutlined, FilePdfOutlined } from '@ant-design/icons-vue'

  //VARIABLES
  const invoiceLoading = ref(false)
  const invoiceDataTable = ref([])
  const year = ref(new Date().getFullYear())
  const monthsEnglish = new Map([["Januar","January"], ["Februar","February"], ["März","March"], ["April","April"], ["Mai","May"],
  ["Juni","June"], ["Juli","July"], ["August","August"], ["September","September"], ["Oktober","October"],["November","November"], ["Dezember","December"],])
  const invoiceCols = [
    {
      title: 'Monat',
      key: 'month',
      dataIndex:'month',
    },
    {
      title: 'PDF Herunterladen',
      key: 'download',
      align: 'center'
    },
    {
      title: 'CSV Herunterladen',
      key: 'downloadCsv',
      align: 'center'
    },
  ]

  //LIFECYCLE HOOKS
  onMounted(async () => {
    await getInvoiceMonths(year.value)
  })

  //FUNCTIONS
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  async function getInvoiceMonths (year) {
    var table = [
      {
        month: 'Dezember',
      },
      {
        month:'November',
      },
      {
        month:'Oktober',
      },
      {
        month:'September',
      },
      {
        month:'August',
      },
      {
        month:'Juli',
      },
      {
        month:'Juni',
      },
      {
        month:'Mai',
      },
      {
        month:'April',
      },
      {
        month:'März',
      },
      {
        month:'Februar',
      },
      {
        month:'Januar',
      },
    ]
    invoiceLoading.value = true
    var params = new FormData()
    params.append('accountuuid', getCookie('account'))
    params.append('year', year)
    await api.checkActiveInvoices(params).then(response => {
      if (response.data !== null) {
        for (const [i, month] of table.entries()){
          if (!response.data.includes(monthsEnglish.get(month.month))) {
            delete table[i]
          }
        }
      } else table = []
        invoiceDataTable.value = table
        invoiceLoading.value = false
      }).catch(error => {
        console.log(error)
        invoiceLoading.value = false
      })
  }

  function invoiceData(month){
    let params = new FormData()
    params.append('account_uuid', getCookie('account'))
    params.append('month', month)
    params.append('year', year.value)

    api.getInvoice(params).then(response => {
      let filename = response.headers['content-disposition'].split("filename=")[1]
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.log(error)
    })
  }

  function invoiceDataCsv(month){
    let params = new FormData()
    params.append('account_uuid', getCookie('account'))
    params.append('month', month)
    params.append('year', year.value)

    api.getCsvInvoice(params).then(response => {
      let filename = response.headers['content-disposition'].split("filename=")[1]
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.log(error)
    })
  }
</script>

<style scoped>

.centering {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectButton {
  display: flex;
  justify-content: left;
  flex-direction: row;
  width: 300px;
  margin-bottom: 1%;
  margin-top: 40px;
}
</style>
