<template>
  <div style="display: flex; align-items: center; justify-content: space-between; padding:2px">
    <span style="text-align: left; width: 70%;">
      <span v-html="options[1].label" style="word-wrap: break-word; width: 100%;"></span>&nbsp;
        <a-tooltip id="hint" v-if="options[1].popover.content !== null">
          <template #title>{{ options[1].popover.content }}</template>
          <ExclamationCircleOutlined />
        </a-tooltip>
    </span>
    <a-input
        type="number"
        style="width: 60px;"
        v-model:value="artAdd"
        :id="options[1].label"
        :min="0"
        :max="999"
    ></a-input>
  </div>
</template>

<script setup>

import {computed} from "vue";
import store from "@/store";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";

//PROPS
const props = defineProps({
  serverType: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true
  },
  submitButtonClicked: {
    type: Boolean,
    required: false
  },
})

//COMPUTED PROPERTIES
const selectedServer = computed(() => store.getters.selectedServer);

function setSelectedConfiguration (configuration) {
  store.commit('setSelectedConfiguration', configuration)
}
function updateConfig(attribute, value) {
  let server = selectedServer.value;
  server.configuration[attribute] = value;
  setSelectedConfiguration(server.configuration);
}

const artAdd = computed({
  get() {
    return selectedServer.value.configuration[props.options[0]]
  },

  set(value) {
    if (value > 999) {
      value = 999
      updateConfig(props.options[0], parseInt(value));
    }
    if (value > 0) {
      updateConfig(props.options[0], parseInt(value));
    }else{
      updateConfig(props.options[0], 0);
    }
  }
})


</script>

<style scoped lang="scss">

</style>
