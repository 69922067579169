<template>
  <a-modal
      v-if="openModal"
      v-model:open="openModal"
      :title="'Artikel bearbeiten'"
      :closable="false"
      :maskClosable="false"
      :destroyOnClose="true"
      :loading="loading"
      cancelText="Abbrechen"
      @cancel="closeModal"
      @ok="handleSubmit"
      centered>
    <a-form
        :model="form"
        :rules="checkInput"
        :loading="loading"
        layout="vertical"
        @finish="handleSubmit">
      <!-- Article name -->
      <a-row :gutter="12">
        <a-col :md="24" :lg="17">
          <a-form-item ref="articleName" name="articleName">
<!--            <template #label>-->
<!--              <TooltipLabel :title="'Name des Artikels'" :tooltip="'Name des Artikels'"/>-->
<!--            </template>-->
<!--            <a-input v-model:value="form.articleName" :placeholder="'Name des Artikels'" />-->
            <b>{{articleResource.name}}</b>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="12">
        <!-- Article price -->
        <a-col :md="24" :lg="17">
          <a-form-item ref="articlePrice" name="articlePrice">
            <template #label>
              <tooltip-label :title="'Preis'" :tooltip="'Bitte geben Sie den Preis des Artikels an.\nNur Zahlen, Punkt oder Komma sind erlaubt'"/>
            </template>
            <a-input
                v-model:value="form.articlePrice"
                :placeholder="'Preis'" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="12">
        <tooltip-label :title="'Status'" :tooltip="'Status des Artikels. Wenn diese Option aktiviert ist, kann der entsprechende Artikel im Kalkulator verwendet werden. Andernfalls wird der Artikel nicht im kalkulator angezeigt.'"/>
        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" >
          <a-switch v-model:checked="enabledValue" @change="getArticleStatus()" style=" text-align: right; margin-right: 2px;"></a-switch>
        </div>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script setup>

import TooltipLabel from "@/components/TooltipLabel.vue";
import { onMounted, reactive, ref, toRaw, watch } from "vue";
import api from "@/apis/apis";
import {notification} from "ant-design-vue";

//LIFECYCLE HOOKS
onMounted(() => {
  getArticleStatus()
});

//VARIABLES
const enabledValue = ref(false)
const openModal = ref(false)
const loading = ref(false)
const onlyNumberRegex = /^\d+([.,]\d{1,2})*$/ //only digits,comma and dot allowed up to two decimal digits
// const specialChars    = /^[\u00C0-\u017Fa-zA-Z0-9_ .-]*$/
// const noSpacesStartEndRegex = /^[^\s]+(\s+[^\s]+)*$/

//Props
const props = defineProps({
  editarticle: Boolean,
  articleResource: Object,
  accountId: {
    type: String,
    required: true
  },
  articleId: {
    type: String,
    required: true
  }
});

//EMITS
const emit = defineEmits(['reload-articles', 'close-modal'])

//WATCHER
watch(() => props.editarticle ,function(newVal) {
  openModal.value = newVal;
});
watch(() => props.articleResource, (newVal) => {
  if (newVal) {
    // form.articleName = props.articleResource.name
    form.articlePrice = props.articleResource.price
    enabledValue.value = props.articleResource.articleStatus;
  }
}, { deep: true })

const form = reactive({
  // articleName: "",
  articlePrice: ""

})

const checkInput = {
  // articleName : [
  //   { required: true, message: 'Bitte geben Sie dem Artikel eine Bezeichnung.'},
  //   { validator: validateCheckName }
  // ],
  articlePrice: [
    { required: true, message: 'Bitte geben Sie den Preis des Artikel ein.'},
    { validator: validateCheckPrice }
  ]
}
// FUNCTIONS
// async function validateCheckName(rule, value){
//   if (!specialChars.test(value)) {
//     const errorMessage = 'Sonderzeichen sind nicht erlaubt!'
//     return Promise.reject(errorMessage)
//   } else if (!noSpacesStartEndRegex.test(value) ) {
//     const errorMessage = 'Der Name darf nicht mit einem Leerzeichen beginnen oder enden!'
//     return Promise.reject(errorMessage)
//   }
//     return Promise.resolve()
//   }

async function validateCheckPrice(rule, value){
  if(!onlyNumberRegex.test(value)){
    const errorMessage = 'Nur Zahlen, Punkt oder Komma und bis zu maximal zwei Dezimalstellen sind erlaubt!'
    return Promise.reject(errorMessage)
  }
    return Promise.resolve()
  }

function getArticleStatus(){
  props.articleResource.articleStatus = !props.articleResource.articleStatus;
  enabledValue.value = props.articleResource.articleStatus;
}

function closeModal() {

  form.articleName = props.articleResource.name
  form.articlePrice = props.articleResource.price
  enabledValue.value = props.articleResource.articleStatus
  openModal.value = false;
  loading.value = false
  emit('reload-articles')
  emit('close-modal');
}

async function handleSubmit(e) {
  e.preventDefault()
  if (loading.value) return;
  try {
    const values = toRaw(form)
    if (
        // values.articleName ===  props.articleResource.name &&
        values.articlePrice === props.articleResource.price &&
        enabledValue.value === !props.articleResource.articleStatus
    ) {
      closeModal()
      return
    }
    loading.value = true;
    const params = new FormData();
    params.append('accountUuid', props.accountId);
    params.append('articleUuid', props.articleId);
    // params.append('name', values.articleName);
    params.append('name', props.articleResource.name);
    params.append('price', values.articlePrice);
    params.append('enabled', enabledValue.value);

    await api.editAdditionalArticles(params).then(async response =>{
      notification.success({
        message: 'Erfolg',
        description: 'Der Artikel wurde erfolgreich aktualisiert.',
        duration: 6,
      })
      closeModal()
    }).catch(error => {
      closeModal()
      notification.error({
        message: 'Fehler beim Aktualisieren eines Artikels.',
        description:
            `Beim Aktualisieren eines Artikels ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. (${error.response.data})`,
        duration: 0,
      })
    });
  } catch (error) {
    console.log(error)
    notification.error({
      message: 'Fehler beim Aktualisieren eines Artikels.',
      description:
          `Beim Aktualisieren eines Artikels ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. (${error.response.data})`,
      duration: 0,
    })
    loading.value = false
    closeModal()
  }
}

</script>

<style scoped lang="scss">

</style>
