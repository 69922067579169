<template>
  <div>
    <div v-if="serverType === 'cloudServer' || serverType === 'iaas'">
      <div class="tabMockContainer">
        <div :class="['tabMock left', {'active': !osOrTemplateSelect}]" @click="handleOsTemplateSwitchChange(false)">
          Betriebssystem
        </div>
        <div :class="['tabMock right', {'active': osOrTemplateSelect}]" @click="handleOsTemplateSwitchChange(true)">
          Vorlage
        </div>
      </div>
      <!-- <div style="display: flex; justify-content: space-between; margin-top: 10px;">
        <strong style="margin-bottom: 5px;display: block;">{{ osOrTemplateSelect ? 'Vorlage' : 'Betriebssystem' }}</strong>
        <a-switch :loading="templateLoading" @change="handleOsTemplateSwitchChange" v-model:checked="osOrTemplateSelect" checked-children="OS" un-checked-children="Vorlage">Test</a-switch>
      </div> -->
      <!-- Templates from "Vorlage" select -->
      
      <div v-if="osOrTemplateSelect">
        <a-select
          :class="[{ 'selectionRequired': (requireOS && submitButtonClicked)}]"
          id="osSelect"
          show-search
          :listHeight="700"
          :filter-option="filterOption"
          v-model:value="selectedTemplate"
          style="width: 283px;"
          @change="handleSelectedTemplateChange(selectedTemplate, true)"
        >
          <template v-for="item in templateSelection" :key="item.TemplateUuid">
            <a-select-option :value="item.TemplateUuid" :title="item.TemplateName">
              <span style="display: flex; align-items: center;">
                {{ item.TemplateName }}
              </span> 
            </a-select-option>
          </template>
        </a-select>
        <div style="background-color: #fff; color: #000; margin-bottom: 10px; margin-top: 10px; border-radius: 6px;">
          <ConfigFormField
            v-for="(field, j) of filterTemplateLicenses(selectedTemplateLicenseIds)"
            :key="'option' + j"
            :options="field"
            :serverType="serverType"
            :isTemplate="osOrTemplateSelect"
          />
        </div> 
      </div>
      <!-- Direct OS select" -->
      <div v-else>
        <a-select
          id="osSelect"
          :class="[{ 'selectionRequired': (requireOS && submitButtonClicked)}]"
          :listHeight="700"
          v-model:value="os"
          style="width: 283px; margin-bottom: 10px;"
          @change="handleSelectChange"
        >
          <template v-for="item in osPriceList" :key="item.value">
            <a-select-option  :value="item.keyLabel">
              <span style="display: flex; align-items: center;">
                <img :src="item.icon" alt="" style="width: 20px; height: 20px; margin-right: 8px;" />
                  {{item.label}}
              </span>
            </a-select-option>
          </template>
        </a-select>
        <template v-for="item in osPriceList" :key="item.value">
          <div v-if="os === item.keyLabel" style="background-color: #fff; color: #000; margin-bottom: 10px; border-radius: 6px;">
            <ConfigFormField
              v-for="(field, j) of filter(item.keyLabel)"
              :key="'option' + j"
              :options="field"
              :serverType="serverType"
            />
            <ConfigFormField
              v-if="os !== 'Andere'"
              :options="gpuPriceList"
              :serverType="serverType"
            />
          </div>
        </template>
      </div>
    </div>
    <div v-if="serverType === 'wpCloud'" style="display: flex; flex-direction: column;">
      <br>
      <strong>Extra-Speicher(optional):</strong>
      <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span v-html="'Extra-SSD Storage in GB'"></span>&nbsp;
        </span>
        <a-input
          type="number"
          :class="['inputNumberField']"
          v-model:value="SSD"
          :min="0"
          :max="9899"
        ></a-input>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span v-html="'Extra-HDD Storage in GB'"></span>&nbsp;
        </span>
        <a-input
          type="number"
          :class="['inputNumberField']"
          v-model:value="HDD"
          :min="0"
          :max="9999"
        ></a-input>
      </div>
    </div>
  </div>
</template>

<script setup>

//IMPORTS
import api from '@/apis/apis'
import ConfigFormField from "./ConfigFormField.vue";
import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import { notification } from 'ant-design-vue'

//VARIABLES
var selectedRadio = ref('')
const osOrTemplateSelect = ref(false)
const templateLoading = ref(false)
const templateSelection = ref([])
const selectedTemplate = ref('Template wählen')
const selectedTemplateLicenseIds = ref([])
const filterOption = (input, option) => {
  return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}
const emit = defineEmits(['set-os-or-template-select'])

onMounted(() => {
  fetchTemplates()
  osOrTemplateSelect.value = templateDefault.value
})
//PROPS
const props = defineProps({
  serverType: {
    type: String,
    required: true
  },
  requireOS: {
    type: Boolean,
    required: false
  },
  submitButtonClicked: {
    type: Boolean,
    required: false
  },
})

 //COMPUTED PROPERTIES
 const selectedServer = computed(() => store.getters.selectedServer)
 const priceList = computed(() => store.getters.getPriceList)
 const templateDefault = computed (() => {
    if (selectedServer.value.templateUuid && selectedServer.value.templateUuid !== 'notSet') {
      handleSelectedTemplateChange(selectedServer.value.templateUuid, false)
      return true
    }
    return false
  })
 const osPriceList = computed(() => {
  var filteredList = []
  var counter = 1
  for (var [key, value] of store.getters.getPriceList) {
    if(value.isOS) {
      if (key == "Linux"){
        filteredList = filteredList.reverse()
        filteredList.push({value: counter, keyLabel: key, label: value.label, icon: require('@/assets/linus.svg')})
      } else if (key.toLowerCase().includes('debian')) {
        filteredList.push({value: counter, keyLabel: key, label: value.label, icon: require('@/assets/debian.svg')})
      } else if (key.toLowerCase().includes('ubuntu')) {
        filteredList.push({value: counter, keyLabel: key, label: value.label, icon: require('@/assets/ubuntu.png')})
      }
      else {
        filteredList.push({value: counter, keyLabel: key, label: value.label, icon: require('@/assets/windows_icon.png')})
      }
    counter++
    }
  }
  filteredList.push({value: counter, keyLabel: "Andere", label: "Andere", icon: require('@/assets/computer.svg')})
  return filteredList
})

const gpuPriceList = computed(() => {
  const original = store.getters.getPriceList
  const clone = new Map(original)
  var gpuList = []
  var gpuListEntries = []
  for (var [key, value] of clone.entries()) {
    if (value.label.includes('GPU-')) {
      value.name = key
      gpuListEntries.push(value)
    }
  }
  gpuList[0] = 'GPUs'
  gpuList[1] = gpuListEntries
  return gpuList
})

const os = computed({
  get() {
    switch (selectedServer.value.configuration.os) {
      case 'Windows_Server_2022':
        store.commit('setTemplateRootDiskSize', 50)
        break
      case 'Windows_Server_2019':
        store.commit('setTemplateRootDiskSize', 40)
        break
      case 'Windows_11':
        store.commit('setTemplateRootDiskSize', 60)
        break
      default:
        store.commit('setTemplateRootDiskSize', 0)
    }
    return selectedServer.value.configuration.os === '' ? 'Betriebssystem wählen' : selectedServer.value.configuration.os;
  },
  set(value) {
    let server = Object.assign({}, selectedServer.value);
    if (((server.configuration.os).toLowerCase()).includes("windows") === true) {
      server.configuration[server.configuration.os] = 0
    }
    server.configuration.os = value;
    if (Object.keys(selectedServer.value.configuration).length !== 0) {
      setSelectedConfiguration(server.configuration)
    }
    if (((server.configuration.os).toLowerCase()).includes("windows") === true) {
      server.configuration[server.configuration.os] = 1
    }
  }
})

const SSD = computed({
  get() {
    return (selectedServer.value.configuration["SSD"]-100)
  },
  set(value) {
    if (value < 0 || value == "") {
      updateConfig("SSD", 100);
    } else if( value > 3996){
      updateConfig("SSD", 4096);
    } else {
      updateConfig("SSD", parseInt(value)+100);
    }
  }
})

const HDD = computed({
  get() {
    return selectedServer.value.configuration["HDD"]
  },
  set(value) {
    if (value > 4096) {
      updateConfig("HDD", 4096);
    } else {
      updateConfig("HDD", value);
    }
  }
})

//FUNCTIONS
function setSelectedConfiguration (configuration) {
    store.commit('setSelectedConfiguration', configuration)
  }

 function handleSelectChange(value) {
  let server = selectedServer.value
  server.configuration[selectedRadio.value] = ""
  selectedRadio.value = value

  if (((selectedRadio.value).toLowerCase()).includes("windows") === true) {
    if (server.configuration["vCPU"] > 0) {
      server.configuration[selectedRadio.value] = server.configuration["vCPU"]
      }
  }
  // set RootDiskSize based on selected Template thats relevant for a potential autodeployment
  switch (value) {
    case 'Windows_Server_2022':
      store.commit('setTemplateRootDiskSize', 50)
      break
    case 'Windows_Server_2019':
      store.commit('setTemplateRootDiskSize', 40)
      break
    case 'Windows_11':
      store.commit('setTemplateRootDiskSize', 60)
      break
    default:
      store.commit('setTemplateRootDiskSize', 0)
  }
  // reset all resources except for OS, Resources and ZusatzArtikel
  for (var item of priceList.value) {
    if ( !item[1].isOS && !item[1].isResource && item[1].type !=='Zusatz-Artikel') {
      server.configuration[item[0]] = 0
    }
  }
}

async function handleOsTemplateSwitchChange (value) {
  osOrTemplateSelect.value = value
  emit('set-os-or-template-select', value)
  selectedTemplate.value = 'Template wählen'
  selectedTemplateLicenseIds.value = []
  // reset all non-resource items
  let server = selectedServer.value
  for (var item of priceList.value) {
    if (!item[1].isResource && item[1].type !=='Zusatz-Artikel') {
      server.configuration[item[0]] = 0
    }
    store.commit('setTemplateRootDiskSize', 0)
  }
  selectedServer.value.configuration.os = ""

  if (value) {
    await fetchTemplates()
  }
}

async function fetchTemplates () {
  await api.getPPTemplates().then(response => {
    templateLoading.value = true
    templateSelection.value = response.data
    templateLoading.value = false
  }).catch(error => {
    templateLoading.value = false
    notification.error({
      message: 'Fehler beim abfragen der verwendbaren Templates',
      description:
            'Templates konnten nicht geladen werden, bitte versuchen Sie es erneut. ERROR: ' + error.response.data,
      duration: 0
    })
  })
}
function handleSelectedTemplateChange (template, deleteOldData) {
  selectedTemplateLicenseIds.value = []
  let server = selectedServer.value
  // reset all non-resource items
  if (deleteOldData) {
    for (var item of priceList.value) {
      if (!item[1].isResource && item[1].type !=='Zusatz-Artikel') {
        server.configuration[item[0]] = 0
      }
    }
  }
  selectedTemplate.value = template
  server.templateUuid = template
  api.getLicensesFromTemplateUuid(template).then(response => {
      selectedTemplateLicenseIds.value = response.data === null ? [] : response.data
    }).catch(error => {
      notification.error({
        message: 'Fehler beim abfragen der verwendbaren Templates',
        description:
              'Templates konnten nicht geladen werden, bitte versuchen Sie es erneut. ERROR: ' + error.response.data,
        duration: 0
      })
    })

    api.getRootDiskSizeForTemplate(template).then(response => {
      store.commit("setTemplateRootDiskSize", response.data === null ? 50 : response.data)
    }).catch(error => {
      // just in case because most template have a size of 50 GB
      store.commit("setTemplateRootDiskSize", 50)
      console.log(error)
    })
}

function filter(osName) {
  const original = store.getters.getPriceList
  var clone = new Map(original)
  for (var [key, value] of clone.entries()) {
    if (value.isOS || value.isResource || value.label.includes('GPU')) {
      clone.delete(key)
    }
    if (value.label.includes('TSPlus Trial')){
      clone.delete(key)
    }
    if (osName === 'Linux' || osName.toLowerCase().includes('debian') || osName.toLowerCase().includes('ubuntu')) {
      if (value.label === 'LibreOffice Lizenz' || value.label.includes('Cyber Cloud')){
        continue
      }
    }
    if (osName === 'Windows_11' || osName === 'Windows_10') {
      if (value.label.includes('LibreOffice Lizenz') || value.label.includes('MS Office') || value.label.includes('TSPlus') || value.label.includes('MSSQL') ||value.label.includes('Acronis') || value.label.includes('Cyber Cloud')){
        continue
      }
    }
    if (!osName.includes('Server')) {
      clone.delete(key)
    }
  }
  return clone
}

function filterTemplateLicenses(licenseIdList) {
  const original = store.getters.getPriceList
  var clone = new Map(original)
  var hasTsPlus = licenseIdList.filter(license => license.LicenseName.includes("TSPlus ") && license.LicenseName.includes(" User"))[0]
  var hasMsOffice = licenseIdList.filter(license => license.LicenseName.includes("MS Office ") && license.LicenseName.includes(" User"))[0]
  for (var [key, value] of clone.entries()) {
    var licenseMatch = licenseIdList.filter(license => license.LicenseId === value.data.tagId)[0]
    if (!licenseMatch && hasTsPlus && value.data.tagId === 17) {
      selectedServer.value.configuration["TSPlus_1_User"] = hasTsPlus.LicenseName.split(" ")[1]
    } else if (!licenseMatch && hasMsOffice && value.data.tagId === 39) {
      selectedServer.value.configuration["MS_Office_2021_1_User"] = hasMsOffice.LicenseName.split(" ")[3]
    } else if (licenseMatch && value.isOS) {
      selectedServer.value.configuration.os = key
      // set count of OS to cpu count if WindowsOS
      if (((selectedServer.value.configuration.os).toLowerCase()).includes("windows")) {
        if (selectedServer.value.configuration["vCPU"] > 0) {
          selectedServer.value.configuration[key] = selectedServer.value.configuration["vCPU"]
          } else {
            selectedServer.value.configuration[key] = 1
          }
      } else {
        selectedServer.value.configuration[key] = 1
      }
    } else if (!licenseMatch) {
      clone.delete(key)
    }
  }
  return clone
}

function updateConfig(attribute, value) {
  let server = selectedServer.value;
  server.configuration[attribute] = value;
  setSelectedConfiguration(server.configuration);
}

</script>

<style lang="scss" scoped>

.tabMockContainer {
  display: flex;
  margin: 10px 0 10px 0;
  
}

.tabMock {
  color: rgba(0, 0, 0, 0.329);
  width: 50%;
  height: 30px;
  align-items: center;
  background: #EEEEEE;
  display: flex;
  font-weight: bold;
  justify-content: center;
  
}
.left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.tabMock:hover {
  cursor: pointer;
}
.tabMock.active {
  color: #D02C2A;
  background: white;
  pointer-events: none;
}
 
.inputNumberField {
  width: 60px;
}
.ant-radio-button-wrapper{
  width: 100%;
  border-radius: 50px;
  background-color: rgb(233, 231, 231);
  margin-bottom: 5px;
}
.ant-radio-button-wrapper:hover{
  background-color: rgb(207, 205, 205);
}
.ant-radio-button {
  width: 100%;
  background-color: rgb(233, 231, 231);
  margin-bottom: 5px;
}
.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}
.os-buttons {
  width: 100%;
  .btn {
    border-radius: 50rem;
    width: 100%;
    margin: 0.25rem 0 !important;
    text-align: left;
    &.active {
      z-index: 0;
    }
    &,
    & > div {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.os-field {
  background-color: #fff;
  color: #000;
  margin-bottom: 10px;
}
</style>
