<template>
  <div class="mainNetwork">
    <div class="network-connection deactivated"></div>
    <div id="networkCloudAndConnections" style="position: absolute;">
      <div class="network-connection horizontal"></div>
      <div class="network-connection vertical"></div>
      <div class="network-cloud">
        <div class="title">
          Netzwerk {{ network }}<br/>
          192.168.{{ network }}.0/24
        </div>
      </div>
    </div>
    <div class="network-container" style=" display: flex;">
      <div class="iaas-server-height" style=" width: 220px;">
        <div :class="['panel panel-iaas clickable text-center flexBetween']" name="createInstancesModal" key=""
             @click="openModal(props.network)">
            <span class="clickable">
              <img width="20" src='../../assets/add_cross.svg'/>
            </span>
          <span class="createNewServerText">Neuen Server erstellen</span>
        </div>
      </div>
      <div class="iaasServerContainer">
        <div v-for="(server, i) in servers" :key="i">
          <IaaSServer :server="server"/>
        </div>
      </div>
      <a-popover content="Leeres Netzwerk erstellen">
        <div :class="['saveEmptyNetworkButton', 'clickable', {'disabled' : saveEmptyNetworkBtnState}]" @click="$emit('saveNetworkModal', network)">
          <SaveOutlined />
        </div>
      </a-popover>
      <a-popover content="Netzwerk löschen">
        <div class="deleteButton clickable" @click="$emit('deleteNetworkModal', network)">
          <DeleteOutlined/>
        </div>
      </a-popover>
      <a-card v-if="!isSidebarOpen" class="deployButtons" :id="'network' + network">
        <div style="display: flex; height: 120px;">
          <div class="deployVmButton" @click="handleOpenCreateServer()">
            <img src="../../assets/Icons_Serverschrank_ohne_Schatten.svg" style='height: 90px'/>
            <span style="color: white; font-weight: bold;">Server</span>
          </div>
          <div class="deployContainerButton" @click="handleOpenCreateContainer()">
            <img src="../../assets/container_single.jpeg" style='height: 90px'/>
            <span style="color: white; font-weight: bold;">Container</span>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script setup>

//IMPORTS
import IaaSServer from "@/components/server/IaaSServer.vue";
import {ref, onMounted, computed, reactive} from 'vue'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import store from '@/store'

//VARIABLES
var eventListenerActive = []

//LIFECYCLE HOOKS
onMounted(() => {
   createNetworkObject()
})


//PROPS
const props = defineProps({
  network: {
    type: Number,
    required: true,
  },
  last: {
    type: Boolean,
    default() {
      return false;
    },
  },
})

//COMPUTED PROPERTIES
const saveEmptyNetworkBtnState = computed(()=> {
  const allServers = store.getters.serversByType("iaas").filter(el => el.netId === props.network.toString())
  const allContainers = store.getters.serversByType("containerIaaS").filter(el => el.netId === props.network.toString())
  const allNetServers = store.getters.serversByType("network").filter(el => el.id === props.network)
  if (allServers.length !== 0 || allContainers != 0 || allNetServers.length !== 0) {
    return true
  }
  return false
})
const priceList = computed(() => store.getters.getPriceList)

const servers = computed(() => {
  const allServers = store.getters.serversByType("iaas").filter(el => el.configuration.network === props.network)
  const allContainers = store.getters.serversByType("containerIaaS").filter(el => el.configuration.network === props.network)
  return allServers.concat(allContainers)
})

const networks = computed(() => {
  const allNetworks = store.getters.getNetwork
  return allNetworks
})


const isSmallScreen = computed(() => window.screen.width < 992)
const selectedServer = computed(() => store.getters.selectedServer)
const isSidebarOpen = computed(() => store.getters.isSidebarOpen)

//FUNCTIONS
//Create a network object the first time the Iaas server is called up in the calculator
function createNetworkObject(){
  var networkObjects = store.getters.getNetworkObjectList
  var serverlist = store.getters.serverList
  if(networkObjects.length == 0 && serverlist.length == 0){
    let network = {type: "network", name: "network", configuration: {}, id: props.network}
    store.commit('setNetwork', network)
    store.commit('pushNetworkObjectList', network)
  }
}

function openModal(networkValue) {
  const deploymentButtonCheck = (e) => {
    var openThisPopover = document.getElementById(`network${networkValue}`)
    var createInstanceButtons = document.getElementsByName('createInstancesModal')
    if (openThisPopover !== null && !openThisPopover.contains(e.target)) {
      for (let i = 0; i < createInstanceButtons.length; i++) {
        if (createInstanceButtons[i].contains(e.target)) {
          var popoverList = document.getElementsByClassName('deployButtons')
          if (popoverList.length !== 0) {
            for (var openPopover of popoverList) {
              if (openPopover.style.display === 'inline' && openPopover.id !== networkValue) {
                openPopover.style.setProperty('display', 'none')
              }
            }
          }
          if (openThisPopover.style.display === 'inline') {
            openThisPopover.style.setProperty('display', 'none')
          } else {
            openThisPopover.style.setProperty('display', 'inline')
          }
          return
        }
      }
      if (openThisPopover.style.display === 'inline') {
        openThisPopover.style.setProperty('display', 'none')
      }
    }
  }
  if (!eventListenerActive.includes(networkValue)) {
    document.addEventListener('click', deploymentButtonCheck)
    eventListenerActive.push(networkValue)
  } else {
    document.removeEventListener('click', deploymentButtonCheck, true)
    eventListenerActive = []
    openModal(networkValue)
  }
}

function handleOpenCreateServer() {
  const productSelectionContainerEl = document.getElementById('productSelectionContainer')
  
  productSelectionContainerEl?.scrollIntoView({behavior: "smooth"})
  if (selectedServer.value.type === 'iaas' && Object.keys(selectedServer.value).length !== 0) {
    return
  }

  let server = {type: "iaas", name: "Server", netId: "", configuration: {}, id: Date.now()}

  for (let item of priceList.value) {
    if (item[0]) { // Überprüfen, ob item[0] definiert ist
      server.configuration[item[0]] = 0;
    }
  }

  server.netId = props.network.toString()
  server.configuration.network = props.network
  server.configuration.os = ""
  store.commit('setSelectedServer', server)
  store.commit("addServer")
  store.commit("setSidebarVisibility", true)

  const openThisPopover =
      document.getElementById(`network${props.network}`)

  if (openThisPopover?.style.display === 'inline') {
    openThisPopover.style.setProperty('display', 'none')
  }

}

function handleOpenCreateContainer() {

  const productSelectionContainerEl = document.getElementById('productSelectionContainer')

  productSelectionContainerEl?.scrollIntoView({behavior: "smooth"})

  if (selectedServer.value.type === 'containerIaaS' && Object.keys(selectedServer.value).length !== 0) {
    return
  }

  let server = {type: "containerIaaS", name: "IaaS Container", netId: "", configuration: {}, id: Date.now()}

  for (let item of priceList.value) {
    server.configuration[item[0]] = 0
  }

  server.netId = props.network.toString()
  server.configuration.network = props.network
  server.configuration.os = ""
  store.commit('setSelectedServer', server);
  store.commit("addServer")
  store.commit("setSidebarVisibility", true)

  const openThisPopover = document.getElementById(`network${props.network}`)

  if (openThisPopover?.style.display === 'inline') {
    openThisPopover.style.setProperty('display', 'none');
  }
}

</script>


<style lang="scss" scoped>
.deployButtons {
  display: none;
  background-color: white;
  position: absolute;
  height: 160px;
  top: 20%;
  left: 38%;
  width: 400px;
  z-index: 10000;
  border-radius: 6px;
  box-shadow: 0 1px 14px rgba(0, 0, 0, .2);
}

.deployVmButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #3D7CAF;
}

.deployVmButton:hover {
  cursor: pointer;
}

.deployContainerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #3D7CAF;
  margin-left: 10px;
}

.deployContainerButton:hover {
  cursor: pointer;
}

.iaasServerContainer {
  width: 450px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 10px;
  padding: 35px 0;
}

.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}

.flexBetween {
  display: flex;
  flex-direction: column;
}

.mainNetwork {
  height: auto;
  display: flex;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.network-container {
  width: auto;
  min-height: 200px;
  overflow: auto;
  position: relative;
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.network-cloud {
  position: absolute;
  top: -20px;
  left: -15px;
  width: 150px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 5;
}

.kdz-bg-grey {
  margin-left: 50px;
}

.iaas-server-height {
  height: 220px;
}

.network-connection {
  position: relative;
  background-color: #BEBEBE;
  // display: none;
  z-index: 4;

  &.horizontal {
    top: 100px;
    height: 10px;
    width: 55px;
  }

  &.vertical {
    top: 10px;
    height: 100px;
    width: 10px;
    left: 0px;
  }

  &.deactivated {
    left: 0px;
    height: 104%;
    width: 10.79px;
    //background-color: #f9c87f;
    // border: 1px solid black;
  }
}

.saveEmptyNetworkButton{
  font-size: 32px;
  top: 3px;
  position: absolute;
  right: 50px;
}

.disabled {
  color: #d3d3d3;
  pointer-events: none;
}

.deleteButton {
  position: absolute;
  font-size: 32px;
  top: 3px;
  right: 10px;
}

.title {
  text-align: center;
  color: white;
  font-weight: bold;
  padding-bottom: 20px;
  font-size: 90%;
}

.panel {
  position: absolute;
  width: 200px;
  //height: 115px;
  padding: 30px 10px;
}

.panel-iaas {
  bottom: 50%;
  transform: translateY(100%);
  left: 10px;
  font-size: 14px;
  width: 180px;
  padding: 20px 0;
}

@media (min-width: 1161px) {
  .kdz-bg-grey {
    margin-left: 0;
  }

  .iaas-server-height {
    height: 350px;
  }

  .network-cloud {
    top: 30px;
    left: 55px;
    width: 200px;
    height: 150px;
    background: url('../../assets/cloud.png') no-repeat;
    background-size: contain;
  }
  .network-container {
    width: 100%;
    min-height: 250px;
    overflow: auto;
    margin: 0 0 0 3rem;
  }
}

@media (max-width: 1200px) {
  .kdz-bg-grey {
    margin-left: 0;
  }

  .iaas-server-height {
    height: 300px;
  }

  .network-cloud {
    top: 50px;
    left: 55px;
    width: 160px;
    height: 120px;
    background: url('../../assets/cloud.png') no-repeat;
    background-size: contain;
  }
  .iaasServerContainer {
    justify-content: left;
  }
  .network-container {
    width: 100%;
    min-height: 250px;
    overflow: auto;
    //margin: 0 3rem;
  }
  .mainNetwork {
    width: 600px;
  }

  .createNewServerText {
    font-size: 90%;
  }

  .network-connection {

    &.deactivated {
      width: 10.18px;
      //background-color: #f9c87f;
      // border: 1px solid black;
    }
  }
  .panel {
    width: 140px;
    height: 84px;
    padding: 21px 7px;
    left: 40px;
  }
  .trashBinImage {
    width: 20px;
  }
}

@media (max-width: 930px) {
  .network-connection {

    &.deactivated {
      width: 10.25px;
      //background-color: #f9c87f;
      // border: 1px solid black;
    }
  }
  .mainNetwork {
    width: 450px;
  }
  .iaasServerContainer {
    justify-content: center;
  }
}

@media (max-width: 790px) {
  .network-connection {
    &.deactivated {
      height: auto;
      //background-color: #f9c87f;
      // border: 1px solid black;
    }
  }
}

@media (max-width: 490px) {
  .iaasServerContainer {
    justify-content: center;
    width: 365px;
    margin-left: 0px;
    padding: 0px;
  }
  .mainNetwork {
    width: 370px;
  }
  .panel {
    left: 5px;
  }
  .network-cloud {
    left: 10px;
  }
  .deleteButton {
    right: 0px;
  }
}
</style>