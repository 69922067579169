<template>
    <a-modal
      v-if="openModal"
      style="height: 50em;"
      v-model:open="openModal"
      :title="'Benutzerkonto bearbeiten'"
      :closable="false"
      :maskClosable="false"
      :destroyOnClose="true"
      :confirmLoading="loading"
      cancelText="Abbrechen"
      @cancel="closeModal"
      @ok="handleSubmit"
      centered>
        <a-form
          ref="formRef"
          :model="form"
          :rules="rules"
          :loading="loading"
          layout="vertical"
          @finish="handleSubmit">
          <!-- WP Implementation -->
          <a-form-item ref="accountName" name="accountName">
            <template #label>
              <TooltipLabel :title="'Name des Benutzerkontos'" :tooltip="'Name des eigentlichen Benutzerkontos, welches vom System benutzt wird. Keine Sonderzeichen erlaubt um Requestfehler zu verhindern.'"/>
            </template>
            <a-input v-model:value="form.accountName" :placeholder="'Name des Benutzerkontos'" />
          </a-form-item>
          <a-form-item ref="companyname" name="companyname">
            <template #label>
              <tooltip-label :title="'Firmenname'" :tooltip="'Firmenname, welcher in den Rechnungen angezeigt wird. Alle Sonderzeichen sind erlaubt, da dies nur ein reiner Anzeige-Name und sonst nicht für das System relevant ist.'"/>
            </template>
            <a-input
              v-model:value="form.companyname"
              placeholder="Name der Firma" />
          </a-form-item>
          <!-- Customer NR -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="customerID" name="customerID">
                <template #label>
                  <tooltip-label :title="'Kundennummer'" :tooltip="'Bitte geben Sie eine Kundennummer an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, -_'"/>
                </template>
                <a-input
                  v-model:value="form.customerID"
                  :placeholder="'Kundennummer'" />
              </a-form-item>
            </a-col>
            <!-- Contract NR -->
            <a-col :md="24" :lg="12">
              <a-form-item ref="contractNumber" name="contractNumber">
                <template #label>
                  <tooltip-label :title="'Vertragsnummer'" :tooltip="'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.'"/>
                </template>
                <a-input
                  v-model:value="form.contractNumber"
                  :placeholder="'Vertragsnummer'"/>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- Street + Nr -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="17">
              <a-form-item ref="street" name="street">
                <template #label>
                  <tooltip-label :title="'Straße + Hausnummer'" :tooltip="'Bitte geben Sie eine gültige Adresse an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                </template>
                <a-input
                v-model:value="form.street"
                  :placeholder="'Straße + Hausnummer'"/>
              </a-form-item>
            </a-col>
            <!-- Postal Code -->
            <a-col :md="24" :lg="7">
              <a-form-item ref="postalcode" name="postalcode">
                <template #label>
                  <tooltip-label :title="'PLZ'" :tooltip="'Bitte geben Sie eine Postleitzahl an.\nPostleitzahlen haben maximal 8 Ziffern.'"/>
                </template>
                <a-input
                v-model:value="form.postalcode"
                  :placeholder="'PLZ'"/>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- City -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="city" name="city">
                <template #label>
                  <tooltip-label :title="'Stadt'" :tooltip="'Bitte geben Sie einen Stadtnamen an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                </template>
                <a-input
                v-model:value="form.city"
                  :placeholder="'Stadt'"/>
              </a-form-item>
            </a-col>
            <!-- Country -->
            <a-col :md="24" :lg="12">
              <a-form-item ref="country" name="country">
                <template #label>
                  <tooltip-label :title="'Land'" :tooltip="'Bitte geben Sie ein gültiges Land an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                </template>
                <a-input
                  v-model:value="form.country"
                  :placeholder="'Land'"/>
              </a-form-item>
            </a-col>
          </a-row>
      </a-form>
    </a-modal>
</template>

<script setup>

//IMPORTS
import api from '@/apis/apis'
import { ref, reactive, toRaw, watch } from 'vue'
import TooltipLabel from '@/components/TooltipLabel.vue'
import { notification } from 'ant-design-vue'

//VARIABLES
const emailRegex  	      = /^[^@]+@[^@]{2,}\.[^@]{2,}$/
const specialCharsMail    =  /[-_@.]/
const specialChars        = /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/
const customerIdRegex     = /^[0-9]*$/ //only digits
const contractNumberRegex = /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/ //only letters, digits, -_.
const cityNameRegex       = /^[\u00C0-\u017Fa-zA-Z0-9- ]*$/
const loading             = ref(false)
const openModal           = ref(false)
const tempMitData         = ref([])
const formRef             = ref(null)

const form = reactive({
  accountName: undefined,
  city: undefined,
  postalcode: undefined,
  street: undefined,
  country: undefined,
  customerID: undefined,
  companyname: undefined,
  contractNumber: undefined
})

const rules = reactive({
  accountName: [
    { required: true, message: '' },
    { validator: validateAccountName }
  ],
  companyname: [{ required: true, message: 'Bitte geben Sie den Namen der Firma an.' }],
  contractNumber: [
    { required: false, message: 'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.' },
    { validator: validateContractNumber }
  ],
  city: [
    { required: true, message: 'Bitte geben Sie eine Stadt an.' },
    { validator: validateCity }
  ],
  postalcode: [
    { required: true, message: 'Bitte geben Sie eine Postleitzahl an.'},
    { validator: validatePlz }
  ],
  street: [
    { required: true, message: 'Bitte geben Sie eine Adresse an.' },
    { validator: validateStreet }
  ],
  country: [
    { required: true, message: 'Bitte geben Sie ein Land an.' },
    { validator: validateCountry }
  ],
  customerID: [
    { required: true, message: 'Bitte geben Sie eine Kundennummer an.' },
    { validator: validateCustomerID }
  ],
})

//PROPS
const props = defineProps({
  editAccountModal: {
    type: Boolean,
    required: true
  },
  accountResource: {
    type: Object,
    required: true
  }
})

//WATCH
watch(() => props.editAccountModal, (newVal) => {
  if (newVal === true) {
    form.accountName = props.accountResource.accountname
    listMitAccountData()
  }
  openModal.value = newVal
})

//EMITS
const emit = defineEmits(['reload-accounts', 'close-modal']);


//FUNCTIONS
function closeModal () {
  emit('close-modal')
}

function listMitAccountData () {
  // call function to retrieve user data here
  let getAccountDataParams = new FormData()
  getAccountDataParams.append('accountuuid', props.accountResource.accountuuid)
  api.getMitAccountData(getAccountDataParams).then(response => {
    tempMitData.value   = response.data
    form.companyname    = response.data.CustomerName
    form.customerID     = response.data.CustomerNumber
    form.country        = response.data.Country
    form.street         = response.data.Address
    form.postalcode     = response.data.Plz
    form.city           = response.data.City
    form.contractNumber = response.data.ContractNumber
  }).catch(error => {
    console.log(error)
  })
}

async function handleSubmit (e) {
  e.preventDefault()
  if (loading.value) return

  if (!formRef.value) {
    console.error('Form reference is not set.');
    return;
  }
  await formRef.value.validate().then(async () => {
    const values = toRaw(form)
    loading.value = true

    // WP Implementation
    var params = new FormData()
    params.append('accountuuid', props.accountResource.accountuuid)
    params.append('domainuuid', props.accountResource.domainuuid)
    params.append('newName', values.accountName)
    params.append('customerName', values.companyname)
    params.append('customerNumber', values.customerID)
    params.append('country', values.country)
    params.append('city', values.city)
    params.append('streetName', values.street)
    params.append('plz', values.postalcode)
    if (values.contractNumber == undefined) {
      params.append('contractNumber', "")
    } else {
      params.append('contractNumber', values.contractNumber)
    }

    await api.updateAccount(params).then(response => {
      emit('reload-accounts')
      openModal.value = false
      loading.value = false
      emit('close-modal')
      notification.success({
        message: 'Erfolg',
        description: 'Das Konto wurde erfolgreich aktualisiert.',
        duration: 6
      })
      }).catch(error => {
        console.log(error)
        loading.value = false
        openModal.value = false
        emit('close-modal')
      let errorMsg = error.response.data.split('):')[1];
      if (errorMsg === undefined){
        errorMsg = error.response.data
      }
        notification.error({
          message: 'Fehler beim Aktualisieren des Kontos.',
          description:
              'Bei der Aktualisierung des Kontos ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. (' + errorMsg + ')',
          duration: 0
        })
      })
        emit('close-modal')
    }).catch(error => {
      console.log(error)
      loading.value = false
    })
  }

  function validateAccountName (rule, value) {
    var errorMessage = 'Der Benutzername erfüllt nicht alle Anforderungen!'
    var validated = true;
    if (!specialChars.test(value)) {
      validated = false;
      errorMessage += "\nSonderzeichen sind nicht erlaubt!"
    }
    if (value==undefined || value.length < 4 ) {
      validated = false;
      errorMessage += "\nDer Benutzername muss mindestens 4 Zeichen lang sein!"
    }
    if (validated){
      return Promise.resolve()
    } else {
      return Promise.reject(errorMessage)
    }
  }

  function validateCustomerID(rule, value) {
    const errorMessage = 'Bitte geben Sie eine gültige Kundennummer an.\nNur Zahlen sind erlaubt!'
    if (value!=undefined && customerIdRegex.test(value)) {
      return Promise.resolve()
    }
    return Promise.reject(errorMessage)
  }

  function validateContractNumber(rule, value) {
    const errorMessage = 'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.'
    if (contractNumberRegex.test(value)) {
      return Promise.resolve()
    }
      return Promise.reject(errorMessage)
  }

  function validateCity(rule, value) {
    const errorMessage = 'Bitte geben Sie einen gültigen Stadtnamen an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
    if (value!=undefined && cityNameRegex.test(value)) {
      return Promise.resolve()
    }
      return Promise.reject(errorMessage)
  }

  function validateStreet(rule, value) {
    const errorMessage = 'Bitte geben Sie eine gültige Adresse an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
    if (value!=undefined && cityNameRegex.test(value)) {
      return Promise.resolve()
    }
      return Promise.reject(errorMessage)
  }

  function validateCountry(rule, value) {
    const errorMessage = 'Bitte geben Sie ein gültiges Land an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
    if (value!=undefined && cityNameRegex.test(value)) {
      return Promise.resolve()
    }
    return Promise.reject(errorMessage)
  }

  function validatePlz (rule, value) {
    const errorMessage = 'Die maximale Länge der Postleitzahl beträgt 8!'
    if (value!=undefined && value.length <= 8) {
      return Promise.resolve()
    }
      return Promise.reject(errorMessage)
  }

</script>
